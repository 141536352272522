.submenu {
  @include undolist;
  background-color: #000;
  background-color: rgba(#000, 0.7);
  text-align: center;
  font-size: rem-calc(15);
  padding: $spacing $spacing * 3;
  margin-bottom: 0;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-left: $column-gutter * 0.5;
    padding-right: $column-gutter * 0.5;
  }
  @media #{$medium-up} {
    text-align: left;
    padding: 0;
  }
  li {
    border-bottom: 1px solid #666;
    border-bottom-color: rgba(#fff, 0.25);
    margin: 0;
    padding: $spacing * 0.5 0;
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      display: inline-block;
      border-bottom: 0;
      border-left: 1px solid #666;
      border-left-color: rgba(#fff, 0.25);
      padding: 0 $spacing * 0.5;
      margin: $spacing * 0.25 0;
      // transition-duration: 0.5s;
    }
    @media #{$medium-up} {
      padding: 0;
      position: static;
      border-bottom: 0;
    }
    &:first-child {
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        border-left: 0;
      }
      @media #{$medium-up} {
        a:before {
          display: none;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
    &.has-submenu {
      &:after {
        @media screen and (min-width: 40em) {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          transform: rotate(45deg);
          margin-left: $spacing * 0.125;
          position: relative;
          top: -3px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
      > a {
        @media #{$medium-up} {
          &:after {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            transform: translateY(-50%) rotate(-45deg);
            position: absolute;
            top: 50%;
            right: $spacing * 0.5;
          }
        }
      }
    }
    &:hover {
      @media #{$medium-up} {
        background-color: #000;
        a:before,
        + li a:before {
          display: none;
        }
      }
    }
    &:hover > ul,
    &:focus-within > ul,
    ul:hover {
      @media screen and (min-width: 40em) {
        visibility: visible;
        opacity: 1;
        display: block;
        z-index: 1;
      }
    }
  }
  a {
    @include hover;
    color: #fff;
    @media #{$medium-up} {
      display: block;
      padding: $spacing * 0.5 $spacing;
      position: relative;
      &:hover {
        text-decoration: none;
      }
      &::before {
        content: "";
        display: block;
        height: 1px;
        background-color: #666;
        background-color: rgba(#fff, 0.25);
        position: absolute;
        top: 0;
        left: $spacing;
        right: $spacing;
      }
    }
  }

  // Desplegable 2n nivell
  ul {
    margin-bottom: 0;
    font-size: rem-calc(14);
    font-weight: 300;
    line-height: 1.2;
    @media screen and (min-width: 40em) {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      // transition: all 0.15s ease;
      margin-top: 0;
      left: 12px;
      display: none;
      background-color: #fff;
      font-size: rem-calc(15);
      font-weight: normal;
      padding: $spacing * 0.5;
      text-align: left;
      border-top: $spacing * 0.25 solid #000;
      border-top-color: rgba(#000, 0.7);
      min-width: 12rem;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    }
    @media #{$medium-up} {
      left: 100%;
      top: 0;
      bottom: 0;
      width: 100%;
      margin-left: -$spacing;
      border-top: 0;
      padding: 0;
      font-size: rem-calc(15);
      line-height: 1.6;
      // transition: opacity 0.5s ease;
    }
    li {
      margin: $spacing * 0.5 0;
      padding: 0;
      border: 0;
      @media screen and (min-width: 40em) {
        margin: 0 0 $spacing * 0.5;
      }
      @media #{$medium-up} {
        font-size: rem-calc(15);
        margin-bottom: 0;
        background-color: #fff;
      }
      &:hover {
        @media #{$medium-up} {
          background-color: #f5f5f5;
          > a:before,
          + li > a:before {
            display: none !important;
          }
        }
      }
    }
    a {
      @media screen and (min-width: 40em) {
        color: #484848;
      }
      &:before {
        @media #{$medium-up} {
          display: block !important;
          background-color: #666;
          background-color: rgba(#000, 0.15);
        }
      }
      &:after {
        @media #{$medium-up} {
          border-color: #000 !important;
        }
      }
    }

    // Desplegable 3r nivell
    ul {
      display: none !important;
      @media #{$medium-up} {
        display: block !important;
        margin-left: 0;
        background-color: #f5f5f5;
      }
      li {
        &:hover {
          @media #{$medium-up} {
            > a:before,
            + li > a:before {
              display: block !important;
            }
            a {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

// AppendAround
.submenu-container-small {
  @media screen and (min-width: 40em) {
    display: none;
  }
}
.submenu-container-large {
  display: none;
  @media screen and (min-width: 40em) {
    display: block;
    margin-bottom: $spacing;
  }
  @media #{$medium-up} {
    width: 25%;
    max-width: 21rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: absolute;
    z-index: 1;
    // margin-top: rem-calc(-164);
    // margin-top: $spacing;
    .home:not(.subhome) & {
      width: auto;
      max-width: none;
      padding-left: 0;
      padding-right: 0;
      position: static;
      margin-top: 0;
    }
  }
  @media screen and (min-width: 90em) {
    left: 6%;
  }
  @media screen and (min-width: 100em) {
    left: 10%;
  }
  @media screen and (min-width: 110em) {
    left: 13%;
  }
  @media screen and (min-width: 115em) {
    left: 15%;
  }
}
.head + .submenu-container-large {
  @media #{$medium-up} {
    // margin-top: rem-calc(-164);
    top: 15rem;
    .theme-cpnl & {
      top: 21rem;
    }
    /*.theme-cpnl.jocs & {
      top: 19rem;
    }*/
  }
}
