@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(../fonts/Lato/Lato-Hairline.woff2) format('woff2'), url(../fonts/Lato/Lato-Hairline.woff) format('woff'), url(../fonts/Lato/Lato-Hairline.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(../fonts/Lato/Lato-Light.woff2) format('woff2'), url(../fonts/Lato/Lato-Light.woff) format('woff'), url(../fonts/Lato/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/Lato.eot);
  src: local('Lato'), local('Lato Regular'), local('Lato-Regular'), url(../fonts/Lato/Lato.eot?#iefix) format('embedded-opentype'), url(../fonts/Lato/Lato.woff2) format('woff2'), url(../fonts/Lato/Lato.woff) format('woff'), url(../fonts/Lato/Lato.ttf) format('truetype'), url(../fonts/Lato/Lato.svg#Lato) format('svg');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(../fonts/Lato/Lato-Bold.woff2) format('woff2'), url(../fonts/Lato/Lato-Bold.woff) format('woff'), url(../fonts/Lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url(../fonts/Lato/Lato-Black.woff2) format('woff2'), url(../fonts/Lato/Lato-Black.woff) format('woff'), url(../fonts/Lato/Lato-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'), local('Lato-Italic'), url(../fonts/Lato/Lato-Italic.woff2) format('woff2'), url(../fonts/Lato/Lato-Italic.woff) format('woff'), url(../fonts/Lato/Lato-Italic.ttf) format('truetype');
}

