.grid-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
  li {
    margin: 0;
    display: flex;
    padding: $spacing * 0.25;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      width: 33.33%;
    }
    @media #{$large-up} {
      width: 25%;
    }
  }
  a {
    text-decoration: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: $spacing;
    width: 100%;
    position: relative;
    line-height: 1.3;
    @include themify($themes) {
      background-color: themed("boxesColor");
    }

    // no flexbox fallback
    .ie9 & {
      display: block;
    }
    &:hover,
    &:focus {
      background-color: #272727;
    }
  }

  &-big {
    @extend .grid-list; // està fet així i no amb dues classes (.grid-list .grid-list--big) per facilitar aplicar estils a l'editor visual
    font-size: rem-calc(20);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(28);
    }
    li {
      @media #{$large-up} {
        width: 33.33%;
      }
    }
    a {
      justify-content: center;
      @include themify($themes) {
        background-color: themed("primaryColor");
      }
      &:hover,
      &:focus {
        background-color: #272727;
      }
      @media #{$large-up} {
        padding: $spacing * 1.5;
      }
      @media #{$xlarge-up} {
        padding: $spacing * 2;
      }
    }
  }
}
