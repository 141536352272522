.updated {
    display: inline-block;
    float: right;
    padding: 0.125rem 0.75rem;
    color: #fff;
    .theme-cpnl & {
        background: $cpnl-color-secondary;
    }
    .theme-cnl & {
        background: $cnl-color;
    }
}
