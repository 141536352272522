.fitxa {
  display: flex;
  flex-direction: column;
  &__title {
    line-height: 1.2;
    margin-bottom: $spacing;
    @include themify($themes) {
      color: themed("titleColor");
    }
  }
  &__date {
    font-size: rem-calc(14);
    font-weight: bold;
    display: block;
    order: -3;
    margin-bottom: $spacing * 0.25;
  }
  &__cnl {
  	order: -1;
  }
  &__categ {
    font-size: rem-calc(14);
    font-weight: bold;
    margin-bottom: $spacing * 0.25;
    order: -2;
    @include themify($themes) {
      color: themed("linkColor");
    }
    a {
      @include hover;
    }
  }
  &__lead {
    @include themify($themes) {
      color: themed("titleColor");
    }
    font-weight: bold;
    font-size: rem-calc(20);
  }
  &__intro {
    font-weight: bold;
  }
  &__auth {
    font-style: italic;
  }
  p {
    font-size: rem-calc(16);
  }
}

// fitxa agenda
.fitxa__container-1 {
  display: flex;
  flex-direction: column;
  .fitxa__categ {
    order: -1;
  }
}
.fitxa--agenda .date {
  position: relative;
  top: -1em;
}











.fitxa--lectura .wrapper {
    
     h1 {
        background: none;
        color: #000;
        font-size: 2em;
        padding: 0;
        margin-bottom: .7em;
    }
@media screen and (min-width: 50em) {
     dt {
        clear:left;
        display:block;
        float:left;
        height: 1.8em;
        width: 110px;
        clear:left;
        margin-right: 1em;
    }

     dd {
        display:block;
        float:left;
        height: 1.8em;
        width: auto;
    }
    dl:after {
        content: ' ';
        display: block;
        clear:both;
    }
}
     dd.rating-stars {
        img {
            width: 25px;
        }
    }

     p.tags {
        clear: left;
        background: url('../img/icon-etiqueta.png') no-repeat left .2rem;
        margin-top: 1em;
        padding-left: 30px;
        margin-bottom: .7em;
    }

     p.comments {
        background: url('../img/icon-comentaris.png') no-repeat left .2rem;
        padding-left: 30px;
    }


     h3 {
        border: none;
        color: #000;
        font-weight: bold;
        margin-top: 1em;
    }

	ul.checks {
	    margin-top: 20px;
	    @media screen and (min-width: 50em) {
          column-count: 2;
	    }
	    li {
		  font-size: 16px; // this determines the size of the list text and therefore checkmark size
		  list-style-type: none; // remove default list style
		  margin-bottom: 1em; // lists need to be able to breathe
		  padding: 0.25em 0 0 2.5em; // make room for the circle
		  position: relative; // because we want to position the circle in an absolute fashion
    
		&:before {
		  content: " "; // the circle itself is empty
		  display: block;
		  border: solid 0.8em #ff4e69; // create a colored area...
		  border-radius: .8em; // ... and turn it into a circle
		  height: 0;
		  width: 0;
		  position: absolute; // the circle is then put into place
		  left: 0.5em;
		  top: .75em; 
		  margin-top: -0.5em;
		}
		
		&:after {
		  content: " ";
		  display: block;
		  width: 0.5em; // the short bar of the mark is half as long as the long bar
		  height: 0.9em;
		  border: solid #fff;
		  border-width: 0 0.2em 0.2em 0; // creates the inverted "L" shape
		  position: absolute;
		  left: 1em;
		  top: .74em;
		  margin-top: -0.2em;
		  -webkit-transform: rotate(45deg); // tilts the shape
		  -moz-transform: rotate(45deg);
		  -o-transform: rotate(45deg);
		  transform: rotate(45deg);
		}	    
	  }
	}
	
	   

     p.ressenya span {
        margin-right: 3em;
    }

     p.ressenya em {
        color: #AA4500;
    }


      
    
}
#comments {
	

 h3 {
        color: #000;
        font-size: 1.3em;
        text-transform: lowercase;
        border-bottom: none;
        padding-bottom: 0;
    }

      ol {
        list-style-type: none;
        margin-bottom: 2em;
    }
          ol li {
            width: 100%;
            padding-bottom: 25px;
        }
              ol li div {
                background: #EBEBEB;
                padding: 1.5em;
            }

              ol li p {
               padding-left: 0;
            }


      h2 {
        border-top: 2px solid #EBEBEB;
        border-bottom: none;
        color: #000;
        font-weight: normal;
        padding-top: 1em;
    }

   
}