.abc-list {
  list-style: none;
  padding: 0;
  margin: 0;
  @media screen and (min-width: 30em) {
    column-count: 2;
  }
  @media #{$large-up} {
    column-count: 3;
  }
  > li {
    position: relative;
     break-inside: avoid; 
  }
  span {
    color: $cpnl-color;
    font-size: rem-calc(36);
    font-weight: bold;
    text-transform: uppercase;
    /*position: absolute;*/
    left: 0;
    top: 0;
    float:left;
    line-height: 1;
    border-right: 1px solid #979797;
    width: 2.5rem;
  }
  ol {
    list-style: none;
    margin: 0;
    padding: 0 0 0 3.5rem;
    font-weight: normal;
    font-size: rem-calc(16);
    li {
      margin-bottom: $spacing * 0.5;
    }
    a {
      color: $body-font-color;
      @include hover;
    }
  }
}
