.tools {
  text-align: center;
  padding: 0 $spacing;
  @media screen and (min-width: 40em) {
    display: flex;
    padding: 0;
    // margin-top: rem-calc(35);
    margin: $spacing 0;
    clear: left;
  }
  @media screen and (min-width: 50em) {
    position: absolute;
    top: rem-calc(25);
    right: 0;
    z-index: 1;
    padding: 0 $spacing;
    justify-content: flex-end;
    clear: none;
    margin: 0;
    .sticky-nav & {
      z-index: 0;
    }
  }
  @media #{$medium-up} {
    top: rem-calc(41);
  }
  &__wrapper {
    margin-bottom: $spacing;
    @media screen and (min-width: 40em) {
      margin-right: $spacing;
      margin-bottom: 0;
      text-align: left;
      position: relative;
    }
    @media screen and (min-width: 50em) {
      margin-left: $spacing;
      margin-right: 0;
    }
    @media #{$large-up} {
      margin-left: $spacing * 1.5;
    }
    &--search {
      &-mob {
        @media screen and (min-width: 40em) {
          display: none;
        }
      }
      &-dkp {
        display: none;
        @media screen and (min-width: 40.0625em) {
          display: block;
        }
      }
    }
  }
  &__btn {
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: rem-calc(12);
    /*text-transform: uppercase;*/
    &:after {
      content: "";
      display: inline-block;
      @include sprite("arrow");
      position: relative;
      top: -1px;
      margin-left: $spacing * 0.25;
    }
    &--search {
      &:after {
        display: none;
      }
      img {
        margin-bottom: 0;
      }
    }
    &--lang {
      background-color: #d43f56;
      .theme-cnl & {
      	background-color: #2cbbba;
      }
      border-radius: 5px;
	  color: white;
	  padding: .1875rem 1.125rem;
	  margin-right: 0 !important;
	  @media screen and (min-width: 40em) {
	  	margin-right: 20px !important;
	  }
	  &:before {
		  content: "";
	      display: inline-block;
	      @include sprite("ico-idiomes");
	      position: relative;
	      top: 2px;
		  left: -7px;
	      margin-left: $spacing * 0.25;
	  }
	  &:after {
	      @include sprite("arrow-white");
	  }
    }
    &--open {
      &:after {
        transform: rotate(0.5turn);
        top: -2px;
      }
    }
  }
  &__form {
    background-color: #fff;
    @media screen and (min-width: 40em) {
      padding: $spacing * 0.5;
      margin-bottom: 0;
      position: absolute;
      z-index: 1;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      left: 0;
      top: $spacing * 1.5;
    }
    @media screen and (min-width: 50em) {
      left: auto;
      right: 0;
    }
  }
  &__list {
    font-size: rem-calc(14);
    background-color: #fff;
    @media screen and (min-width: 40em) {
      padding: 0 $spacing * 0.5 $spacing * 0.5;
      margin-bottom: 0;
      position: absolute;
      z-index: 1;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    }
    &--xxss {
      text-align: center;
    }
    &--lang {
      background-color: #d43f56;
      .theme-cnl & {
      	background-color: #2cbbba;
      }
      padding: 0 $spacing * 0.5 $spacing * 0.5;
      box-shadow: 0 0 0 0;
	  border-radius: 5px;
	  padding-top: 10px;
	  width: 150px !important;
	  margin: .5rem auto;
      @media screen and (min-width: 40em) {
	      width: calc(100% - 1.25rem) !important;
		  margin-top: -5px;
      }
      
	  li {
	  	margin-top: .5rem;
	  }
      a {
      	color: white !important;
      	display: block;
		padding: .125rem .5rem;
      }
      .active a {
      	background-color:white;
      	border-radius: 5px;
      	color: #d43f56 !important;
      	.theme-cnl & {
	    	color: #2cbbba !important;
	    }
      }
    }
    .sticky-nav & {
      @media #{$medium-up} {
        display: none;
      }
    }
    li {
      margin-top: $spacing * 0.5;
    }
    a {
      color: $body-font-color;
      @include hover;
    }
  }
}
