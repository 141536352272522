.h-dest {
  h2 {
    font-weight: 900;
    font-size: rem-calc(26);
    line-height: 1.6;
    @media #{$medium-up} {
      font-size: rem-calc(28);
    }
    @media #{$large-up} {
      font-size: rem-calc(30);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(32);
    }
    + p {
      font-size: rem-calc(18);
      font-weight: normal;
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
      @media #{$large-up} {
        font-size: rem-calc(22);
      }
    }
  }
}
