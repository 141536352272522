.section {
  padding: $spacing * 1.5 0;
  @media #{$medium-up} {
    padding: $spacing * 2.5 0;
  }
  &--bordered {
    border-bottom: 1px solid #979797;
  }
  &--bg-1 {
    background-color: #efefe9;
  }
  &--bg-2 {
    background-color: #515151;
  }
  &--bg-3 {
    color: #fff;
    background-color: #1e1e1e;
  }
  &--bg-4 {
    color: #fff;
    @include themify($themes) {
      background-color: themed("primaryColor");
    }
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.25),
        transparent
      );
      height: rem-calc(200);
    }
  }
  &--bg-5 {
    color: #fff;
    background-color: #ef4d66;
    position: relative;
    padding: 0;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: rem-calc(77);
      width: 100%;
      opacity: 0.07;
      background-image: linear-gradient(to bottom, #000000, transparent);
    }
  }
  &--bg-6 {
    padding: 0;
    background-color: #c30c28;
  }
  &--bg-logos {
    border-top: 2px solid #efefe9;
    background-color: #ffff;
    border-bottom: 2px solid #efefe9;
    padding: 2.25rem 0 0;
    margin-top: 3rem;
  }
  body:not(.home) & {
    > div > div > *:last-child {
      margin-bottom: 0;
    }
  }
}

.section-divider {
    margin: 3rem 0;
}        
.theme-cnl.home:not(.subhome) {
    .section-divider {
        margin: 3rem 0;
        padding: 3rem 0 0;
        position: relative;
        &:before {
            position:absolute;
            display: block;
            content: ' ';
            height: 1px;
            background-color: #979797;
            top:0;
            left:-100vw;
            right:-100vw;
        }
        &:first-child {
            margin-top: 0;
            &:before {
                display: none;
            }
        }
        &>.section-separate {
           padding: 0;
           .agenda-list {
            margin-bottom: 0;
           }
           ul.destacats {
                margin-bottom: 0;
           }
           ul.dest-list {
	           margin-bottom: -1rem;
	       }
        }
        .h-block {
           margin-bottom: 0;
        }
        .section--bg-logos {
            border-top: none;
            border-bottom: none;
            padding: 0;
            margin-top: 0;
        }
        
    }
}

.subhome {
		
	.section-divider {
		margin: 2rem 0;
		.h-block {
		   margin-bottom: 0;
		}
		&:nth-child(2n) {
		   padding: 3rem 0 3rem;
		   background-color: #F2F2F2;
		   position: relative;
		   &:before {
			  position:absolute;
			  display: block;
	          content: ' ';
			  top:0;
			  bottom:0;
			  left:-100vw;
			  right:-100vw;
			  background:inherit;
			  z-index: -1;
		   }
		   
		   
		}
		&>.section {
		   padding: 0;
	        ul.destacats {
	            margin-bottom: 0;
	        }
		}
		
		ul.circles-list {
	        margin-bottom: -2rem;
	    }
	    
	    ul.xarxes-rounded-list {
            margin-bottom: -2rem;
        }
	    
	    ul.dest-list {
	        margin-bottom: -1rem;
	    }
	    
	    .dest-3 {
	        @media #{$medium-up} {
	            margin-bottom: 0;
	        }
	    }
	    
	    &:last-child {
	        margin-bottom: 0;
	        paddin-bottom: 0;
	    }
	    
	    &.white {
	        background-color: #fff !important;
	        &>.section--bg-logos {
		       padding: 2.25rem 0 0;
		       margin-top: 0;
		    }
	    }
	}
}
