.date-picker-wrapper.two-months {
	font-family: Lato, "Helvetica Neue", Helvetica, Arial, Roboto, sans-serif;
	
	.drp_top-bar {
	   color: #000;
	   font-size: 14px;
	   
	   .apply-btn {
	       border-radius:0;
	       background: #ff4e69;
	       border-color: #ff4e69;
	       font-weight: bold;
	       color: #fff;
	       font-size: 14px;
	       &:hover {
	           color: #ff4e69 !important;
	           background: #fff;
	       }
	   }
	   
	   .selected-days {
	       display: none !important;
	   }
	}
	
	.month-wrapper {
	   min-width: 455px;
	   table {
	       font-family: Lato, "Helvetica Neue", Helvetica, Arial, Roboto, sans-serif;
	       font-size: 12px !important;
           text-transform: none !important;
           border-collapse: collapse;
           border-spacing: 0;
           border: 0;
           margin: 0;
           width: auto;
           line-height: 20px;
           th {
                text-align: center;
                width: 20px;
                height: 20px;
                border: 0;
                padding: 4px 5px;
                background: #fff;
                background-color: rgb(255, 255, 255);
                cursor: pointer;
                vertical-align: middle !important;
                
                &.month-name {
                    padding: 0;
                }
	       }
	       td {
	           padding: 0;
	           text-align: center;
				width: 20px;
				height: 20px;
				border: 0;
				font-size: 12px;
				background: #fff;
				cursor: pointer;
				vertical-align: middle !important;
				
				div.day {
				  margin: 0;
				  width: 20px;
                  height: 20px;
                  line-height: 20px;
                  padding: 4px 5px;
                  
                  
	              &.lastMonth, 
	              &.nextMonth {
	                  color: #999;
	                  display: block;
	              }
	              &.invalid {
	               color: #ccc !important;
	              }
	           }
	       }
	       thead th {
			    background-color: #c30c28;
		   }
		   
		      
		    .month-name, .week-name {
		        text-transform: none;
		    }
	   }
	}

    
    
    .footer {
        margin: 1rem 0;
        font-size: 14px;
        b {
            display: none;
        }
        .custom-shortcut {
        font-weight: bold;
        margin-right: 10px;
        }
    }
}