.main-header {
  position: relative;
  .js-menu-open & {
    background-image: linear-gradient(
      to top,
      rgba(#000000, 0.08),
      rgba(0, 0, 0, 0)
    );
  }
  .theme-cpnl & {
    @media #{$medium-up} {
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.2),
        transparent
      );
      background-position: 0 132px;
      background-repeat: no-repeat;
      background-size: auto 77px;
    }
  }
}
.site-logo {
  line-height: 1;
  font-size: rem-calc(16);
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-weight: 300;
  .theme-cpnl & {
    margin: rem-calc(23) 0;
    @media #{$medium-up} {
      margin: rem-calc(22) 0;
    }
  }
  .theme-cnl & {
    margin: rem-calc(16) 0 rem-calc(15);
    padding-right: rem-calc(50);
    line-height: 1.2;
    @media screen and (min-width: 40em) {
      float: left;
      margin: $spacing 0;
      padding-right: 0;
    }
    @media #{$medium-up} {
      font-size: rem-calc(24);
      margin: $spacing 0;
    }
  }
  .sticky-nav & {
    @media #{$medium-up} {
      opacity: 0;
      // display: none;
    }
  }
  a {
    color: $body-font-color;
    text-decoration: none;
    display: inline-block;
    // .theme-cnl & {
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   width: calc(100% - 50px);
    //   @media screen and (min-width: 40em) {
    //     width: auto;
    //   }
    // }
    // .slc {
    //   display: block;
    //   text-transform: none;
    //   font-weight: 300;
    //   border-top: 1px solid #ccc;
    //   margin-top: $spacing * 0.5;
    //   padding-top: $spacing * 0.25;
    // }
  }
  img {
    display: block;
    margin-bottom: 0;
    .theme-cpnl & {
      height: rem-calc(28);
      @media #{$medium-up} {
        height: rem-calc(67);
      }
    }
    .theme-cnl & {
      height: rem-calc(23);
      margin-bottom: 2px;
      @media #{$medium-up} {
        display: inline;
        height: rem-calc(39);
        margin-bottom: 0;
        margin-right: $spacing * 0.5;
        vertical-align: baseline;
      }
    }
  }
  span {
    font-weight: 900;
  }
}

// hack per Safari per evitar aquest problema: https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/ Seria millor trobar una altra solució però de moment això és el que hi ha.
// _::-webkit-full-page-media,
// _:future,
// :root .main-header.sticky {
//   top: 0;
// }
