.xarxes-rounded-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    font-size: rem-calc(18);
    justify-content: center;
  }
  @include undolist;
  &__item {
    margin: 0 0 $spacing * 2;
    padding: 0 3rem !important;
    width: 100%;
    text-align: center;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media screen and (min-width: 40em) {
      width: 33.33%;
    }
    @media #{$medium-up} {
      width: 20%;
    }
    > a {
      @include hover;
      display: block;
      color: #000;
    }
  }
  a {
      text-decoration: none;
      color: #fff;
      padding: 135px 0 0;
      margin: 0 ;
      display: block;
      position: relative;
      &:before {
         content: ' ';
         position: absolute;
         display: block;
         top: 0;
         left: calc(50% - 58px);
         
         border-radius: 50%;
	     width: 116px;
	     height: 116px;
	     background-color: #d8d8d8;
	     
	     
      }

  }
  .xarxes-rounded-list__item_facebook a:before {
    background:url(img/destacat-facebook.svg) no-repeat left top;
  }  
  .xarxes-rounded-list__item_twitter a:before {
    background:url(img/destacat-twitter.svg) no-repeat left top;
  } 
  .xarxes-rounded-list__item_youtube a:before {
    background:url(img/destacat-youtube.svg) no-repeat left top;
  } 
  .xarxes-rounded-list__item_instagram a:before {
    background:url(img/destacat-instagram.svg) no-repeat left top;
  } 
  .xarxes-rounded-list__item_blog a:before {
    background:url(img/destacat-blog.svg) no-repeat left top;
  }   
  &__title {
    font-size: rem-calc(16);
    font-weight: bold;
    margin-bottom: .25rem;
    text-align: center;
    
  }
  &__subtitle {
    font-size: rem-calc(16);
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
    color: #000;
    margin-bottom: 0;
  }
}
