@import "helpers/helpers";
@import "variables/vars";
@import "variables/settings"; // custom Foundation settings (file copied from 'node_modules/foundation-sites/scss/foundation/_settings.scss')
@import "variables/colors";

.cursos-head > div {
    border-right: none;
}

.section-destacats + .section-destacats {
    margin-top: -7.5rem;
}

.section-destacats + .section-destacats {
    margin-top: -7.5rem;
}
.houdiniForm.cursos-form + .houdiniForm.cursos-form {
    margin-top: -1.5rem;
}

.image-gallery {
    margin-top: 5rem !important;
    figure {
        margin-top: 1.5rem;
        img {
            width: 100%;
        }
    }
}

.mb-2 {
    margin-bottom: 2rem;
}

.datepicker {
    th.prev i::before {
        content: "←";
        display: block;
    }
    th.next i::before {
        content: "→";
        display: block;
    }
}
.datepicker td.active.day,
.datepicker td.active.year {
    color: #fff;
    @include themify($themes) {
        background-color: themed("primaryColor");
    }
}

.protecciodades .clausula {
    background: #fafafa;
    padding: 1.5rem 2rem;
    margin-top: 1.5rem;
    color: #555 !important;

    h1,
    h2,
    h3,
    h4 {
        font-size: .875rem;
        margin: 0 0 1rem;
        color: #555 !important;
    }
    p,
    ul,
    ol {
        font-size: .75rem !important;
        margin: 0 0 0.5rem;
        color: #555 !important;
    }
}

.jocs .head {
	.apartat {
	   display: none;
	}
	.page-title {
	    position: relative;
	    padding-right: 120px;
	    @media #{$large-up} {
	        padding-right: 330px;
	    }
	
	    &:after {
	        content: "";
	        background: url("../img/jocs_itujugues.png") no-repeat left top;
	        background-size: 100%;
	        height: 100px;
	        width: 100px;
	        display: block;
	        position: absolute;
	        top: -26px;
	        right: 0px;
	        @media #{$large-up} {
	            right: 200px;
	        }
	    }
	}
}

.sidebar-container {
    margin-top: 3rem;
    padding: 0.75rem 1.5rem;
    h2,
    h3 {
        font-size: 120% !important;
    }
    p,
    ul,
    li {
        font-size: 100% !important;
    }
    .caixeta,
    p.image {
        border-bottom: 2px solid #efefe9;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
    }
    p.image {
        img {
            width: 100%;
        }
    }
    #randomBlocs li::before {
        display: none;
    }
}

.caixaXXSS {
    h4 {
        border-bottom: 1px solid #000;
        padding-bottom: 0.5rem;
        span {
            display: inline-block;
            margin-left: 8px;
            position: relative;
            bottom: 5px;
        }
        a.more {
            display: none;
        }
    }
    p.usr {
        display: none;
    }
    & > div {
        padding: 1rem;
        max-width: 90%;
        margin: 0 auto;

        p.image {
            margin-bottom: 0;
        }
    }
    a {
        text-decoration: none;
    }
}

.select {
    &.select--select2:after {
        display: none;
    }
    .select2-container {
        width: 100%;
        border-radius: 6px;
        background-image: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#fff),
            to(#dadada)
        );
        background-image: linear-gradient(to bottom, #fff, #dadada);
        font-size: 1rem;
        color: #000;
        font-weight: 400;
        padding: 1rem 3rem 1rem 1.5rem;
        /* 		-webkit-box-sizing: border-box; */
        /* 		box-sizing: border-box;	 */
        .select2-selection--single {
            height: auto;
            background-color: transparent;
            border: none;
        }
        .select2-selection__arrow {
            top: 16px;
            right: 16px;
        }
    }
}
.select2-search--dropdown,
.select2-results__option {
    padding: $spacing * 0.5;
}
.select2-results__options {
    font-size: rem-calc(14);
}
.comics {
    .news__text{
        left: 30%;
        width: 70%;
    }
    .news__img{
        right: 70%;
        width: 30%;
    }    
}
.tools__list{
    width: 80px;
}
.tools__btn{
    margin-right: 20px;
}