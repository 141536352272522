.sp-news {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing * 1.5;
  > *:last-child {
    margin-bottom: 0;
  }
  img {
    order: -2;
    margin-bottom: 0;
    margin-right: auto;
  }
  time {
    display: block;
    font-size: rem-calc(14);
    font-weight: bold;
    order: -1;
    margin: $spacing * 0.5 0;
  }
  &__title {
    font-size: rem-calc(18);
    margin-bottom: $spacing * 0.5;
    font-weight: 900;
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
    a {
      @include hover;
      color: $cpnl-color;
    }
  }
  p {
    font-size: rem-calc(16);
  }
  &--dest {
    .sp-news__title {
      font-size: rem-calc(22);
      line-height: 1.3;
      margin-top: 1rem;
      @media #{$medium-up} {
        font-size: rem-calc(28);
        line-height: 1.2;
      }
      @media #{$large-up} {
        font-size: rem-calc(32);
      }
    }
  }
}
