.h-news {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing * 1.5;
  &__title {
    margin-bottom: $spacing * 0.5;
    font-weight: 900;
    font-size: rem-calc(20);
    line-height: 1.1;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    a {
      @include themify($themes) {
        color: themed("titleColor");
      }
      @include hover;
    }
  }
  time {
    font-weight: bold;
    display: block;
    font-size: rem-calc(14);
    margin-bottom: $spacing * 0.5;
    order: -2;
  }
  .categ {
    font-weight: bold;
    font-size: rem-calc(14);
    margin-bottom: $spacing * 0.5;
    order: -2;
  }
  p {
    margin-bottom: 0;
    font-size: rem-calc(14);
    font-weight: normal;
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
    a {
      @include hover;
      font-weight: bold;
    }
  }
  img {
    margin-bottom: $spacing * 0.5;
    margin-right: auto;
    order: -3;
  }
}
