.dest-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin: 0 0 $spacing;
    padding: 0 $spacing * 0.25;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
  }
  &__wrapper {
    border-left: 1px solid $link-color;
    padding-left: $spacing * 0.5;
  }
  &__title {
    margin-bottom: $spacing * 0.25;
    font-size: rem-calc(18);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
    a {
      @include hover;
      @include themify($themes) {
        color: themed("titleColor");
      }
    }
  }
  &__text {
    margin-bottom: 0;
    font-size: rem-calc(15);
    font-style: italic;
    color: $link-color;
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
    @media #{$large-up} {
      font-size: rem-calc(18);
    }
  }
}
