.filtre {
    padding: $spacing * 0.5 $spacing * 0.75;
    margin-bottom: $spacing * 2;
    background-color: #efefe9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: rem-calc(18);
    }
    button {
        background-color: transparent;
    }
}
