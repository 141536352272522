// https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
// html,
// body {
//   position: fixed;
//   overflow: hidden;
// }
// .main-wrapper {
//   width: 100vw;
//   height: 100vh;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
// }
html {
  scroll-behavior: smooth;
}
body {
  @media screen and (min-width: 40em) {
    overflow-x: hidden;
  }
  @media #{$medium-up} {
    &.theme-cpnl.sticky-nav {
      padding-top: rem-calc(210);
    }
    &.theme-cnl.sticky-nav {
      padding-top: rem-calc(70);
      &.home {
        padding-top: rem-calc(75);
      }
    }
  }
}

.btn-container {
  padding-top: $spacing * 2;
}

// home cnls
.home.theme-cnl,
.home.subhome.theme-cpnl {
  .section--bg-1 .row,
  .section--bg-3 .row,
  .h-block .row {
    margin-left: 0;
    margin-right: 0;
  }
}

// news
.news__text {
  @include grid-column(12);
  @media screen and (min-width: 45em) {
    @include grid-column(6, $push: 6);
    .news__item--simple & {
      @include grid-column(12, $push: 0);
    }
  }
}
.news__img {
  @include grid-column(12);
  @media screen and (min-width: 45em) {
    @include grid-column(6, $pull: 6);
  }
}

// publicacions
.pub-list__img {
  @include grid-column(12);
  @media screen and (min-width: 40em) {
    @include grid-column(5);
  }
}
.pub-list__text {
  @include grid-column(12);
  @media screen and (min-width: 40em) {
    @include grid-column(7);
  }
}

// directori
.dir-mapa {
  @include grid-column(12);
  @media screen and (min-width: 40em) {
    @include grid-column(5);
    margin-bottom: $spacing * 2;
  }
}
.dir-info {
  @include grid-column(12);
  margin-bottom: $spacing * 2;
  @media screen and (min-width: 40em) {
    @include grid-column(7);
    margin-bottom: 0;
  }
}

// text en columnes
.text-2-col {
  @media #{$medium-up} {
    column-count: 2;
    margin-bottom: $spacing;
  }
}
.text-3-col {
  @media #{$medium-up} {
    column-count: 2;
    margin-bottom: $spacing;
  }
  @media #{$large-up} {
    column-count: 3;
  }
}

// fitxa agenda
@media screen and (min-width: 30em) {
  .fitxa__heading {
    @include grid-row($behavior: nest);
  }
  .fitxa__container-1 {
    @include grid-column(9, $push: 3);
  }
  .fitxa__container-2 {
    @include grid-column(3, $pull: 9);
  }
}
@media screen and (min-width: 45em) {
  .fitxa__container-1 {
    @include grid-column(10, $push: 2);
  }
  .fitxa__container-2 {
    @include grid-column(2, $pull: 10);
  }
}
@media #{$medium-up} {
  .fitxa__container-1 {
    @include grid-column(9, $push: 3);
  }
  .fitxa__container-2 {
    @include grid-column(3, $pull: 9);
  }
}

// llistat de jocs
.jocs-list {
  @include block-grid(1);
  @media screen and (min-width: 25em) {
    @include block-grid(2);
  }
  @media screen and (min-width: 40em) {
    @include block-grid(3);
  }
  @media screen and (min-width: 50em) {
    @include block-grid(4);
  }
  @media #{$medium-up} {
    @include block-grid(3);
  }
}

// resultats de cursos
@media #{$medium-up} {
  .cols-wrapper {
    display: flex;
  }
  .cursos-form {
    height: 100%;
  }
}
