.metamenu {
  @include undolist;
  text-align: center;
  font-size: rem-calc(14);
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (min-width: 40em) {
    text-align: left;
    &--bottom {
      text-align: center;
    }
  }
  @media #{$medium-up} {
    &--top {
      .theme-cpnl.sticky-nav & {
        display: none;
      }
    }
  }
  @media (min-width: 75em) and (min-height: 43.75em) {
    margin: $spacing * 2 0;
  }
  li {
    margin-bottom: $spacing * 0.5;
    @media screen and (min-width: 40em) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: $spacing * 0.5;
    }
  }
  a {
    @include hover;
    color: $cpnl-color-secondary;
  }
}
