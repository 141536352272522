table.cursos-table {
  border-collapse: separate;
  font-size: rem-calc(14);
  text-transform: uppercase;
  a {
    color: $body-font-color !important;
    @include hover;
  }
  thead {
    th {
      color: $body-font-color;
      font-size: rem-calc(12);
      background-color: transparent;
      @media #{$large-up} {
        padding-left: $spacing;
        padding-right: $spacing;
      }
      
      a.sort-by {
            padding-right: 20px;
            position: relative;
            display: inline-block;
            width: 100%;
      }
      a.sort-by:before,
      a.sort-by:after {
          border: 4px solid transparent;
          content: "";
          display: block;
          height: 0;
          right: 5px;
          top: 50%;
          position: absolute;
          width: 0;
      }
      a.sort-by:before {
          border-bottom-color: #666;
          margin-top: -9px;
      }
      a.sort-by:after {
          border-top-color: #666;
          margin-top: 1px;
      }
      
      a.sort-active {
        &:before {
            border-bottom-color: #000;
        }
        &:after {
            border-top-color: #000;
        }
      }
      a.sort-asc {
        &:after {
            border-top-color: #ccc;
        }
      }
      a.sort-desc {
        &:before {
            border-bottom-color: #ccc;
        }
      }
    }
    button {
      all: inherit;
      padding: 0;
      cursor: pointer;
      white-space: nowrap;
      &:after {
        display: none;
      }
    }
  }
  tbody {
    tr:nth-child(odd) {
      td {
        background-color: #fff;
        border-top-color: #fff;
        border-bottom-color: #fff;
      }
    }
    tr:nth-child(even) {
      td {
        background-color: #efefe9;
        border-top-color: #efefe9;
        border-bottom-color: #efefe9;
      }
    }
    td {
      padding-top: 0;
      padding-bottom: 0;
      border-top: $spacing * 0.75 solid;
      border-bottom: $spacing * 0.75 solid;
      position: relative;
      font-weight: normal;
      @media #{$large-up} {
        border-top-width: $spacing;
        border-bottom-width: $spacing;
        padding: 0 $spacing;
      }
      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background-color: #cacaca;
        position: absolute;
        right: 0;
        top: 0;
      }
      &:last-child:after {
        display: none;
      }
    }
  }

  .mod {
    font-weight: bold;
  }
  .loc {
    text-transform: none;
    font-size: rem-calc(16);
  }
  .data {
    text-transform: none;
  }
  .hores {
    font-size: rem-calc(18);
/*    
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
*/    
  }
  .nivell {
    min-width: 15%;
    text-transform: none;
    font-size: 1rem;
  }
  .matr {
    font-weight: bold;
    em {
        font-style: normal;
        color: #218838;
    }
    span {
      text-transform: none;
      font-weight: 300;
      display: block;
    }
  }
}
