.collapsible {
  border-top: 1px solid #d1d1d1;
  margin-bottom: $spacing;
  &__title {
    margin: 0;
    color: #000 !important;
    margin-bottom: $spacing * 0.25;
    margin-bottom: 0;
    font-size: rem-calc(16);
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    button {
      all: inherit;
      border: 0;
      padding: $spacing * 0.75 0;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid #d1d1d1;
      background-color: transparent;
      @media #{$medium-up} {
        padding: $spacing 0;
      }
      &:focus {
        outline: 2px solid $focus-color;
        outline-offset: 0.25rem;
        &:not(:focus-visible) {
          outline: 0;
        }
        &:focus-visible {
          outline: 2px solid $focus-color;
          outline-offset: 0.25rem;
        }
      }
      &[aria-expanded="true"] {
        border-bottom: 0;
        svg {
          transform: rotate(0.5turn);
        }
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      svg {
        flex: 0 0 13px;
        margin: 0 $spacing * 0.5;
      }
    }
    + div {
      margin-bottom: $spacing * 0.25;
      padding-bottom: $spacing * 0.5;
      border-bottom: 1px solid #d1d1d1;
      @media #{$medium-up} {
        padding-bottom: $spacing;
      }
      p,
      ol,
      ul {
        font-size: 1rem;
      }
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--type2 {
    border-top: 0;
    .collapsible__title {
      button {
        &[aria-expanded="true"] {
          border: solid #ff4e69;
          border-width: 2px 2px 0;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          padding: $spacing $spacing * 1.5;
          span {
            font-size: rem-calc(20);
          }
          svg {
            display: block;
            flex-basis: auto;
          }
        }
        &[aria-expanded="false"] {
          &:hover {
            text-decoration: underline;
          }
        }
        svg {
          display: none;
        }
      }
      &__head {
        border: solid #ff4e69;
        border-width: 2px 2px 0;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        padding: $spacing $spacing * 1.5;
        font-size: rem-calc(20);
      }
      + div {
        &:not([hidden]) {
          border: solid #ff4e69;
          border-width: 0 2px 2px 2px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          padding: 0 $spacing * 1.5 $spacing $spacing * 1.5;
        }
        p {
          font-weight: normal;
        }
        p.categ {
          text-transform: uppercase;
          font-size: rem-calc(11);
          font-weight: normal;
          @include themify($themes) {
            color: themed("primaryColor");
          }
          a {
            text-decoration: none;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: $spacing;
        }
      }
    }
    .info {
      p {
        margin: 0;
        font-weight: bold !important;
        font-size: rem-calc(14);
      }
      a {
        display: flex;
        gap: $spacing * 0.5;
        align-items: center;
        padding: $spacing * 0.5 $spacing * 0.5;
        border: 1px solid transparent;
        border-radius: 6px;
        @include hover;
        @include themify($themes) {
          color: themed("primaryColor");
        }
        &:hover,
        &:focus {
          border-color: #000;
        }
      }
    }
    .compartir {
      p:first-child {
        font-weight: bold;
        font-size: rem-calc(12);
        margin-bottom: $spacing * 0.5;
      }
      div {
        display: flex;
        align-items: center;
        gap: $spacing;
        margin-bottom: $spacing;
        a {
          display: flex;
        }
      }
      p:last-child {
        font-weight: bold;
        font-size: rem-calc(14);
        margin-bottom: 0;
        a {
          font-weight: normal;
          @include themify($themes) {
            color: themed("primaryColor");
          }
        }
      }
    }
  }
}
