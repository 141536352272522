.slider-container {
  padding: 0;
  @media #{$medium-up} {
    padding-right: $column-gutter * 0.5;
  }
}
@media #{$medium-up} {
	.sub-home-textcontainer {
	    padding-right: $column-gutter * 0.5;
	    padding-left: 0;
	}
}

// Slider CPNL
.slider-cpnl {
  .slick-slide > div {
    height: $slider-h-sm;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    @media #{$xlarge-up} {
      height: $slider-h-xlg;
    }
  }
  &__img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: $slider-h-sm;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    @media #{$xlarge-up} {
      height: $slider-h-xlg;
    }
    &--1 {
      background-image: url("img/barcelona-buildings-city-1388030.jpg");
    }
    &--2 {
      background-image: url("http://picsum.photos/1500/500/");
    }
    &--3 {
      background-image: url("http://picsum.photos/1500/600/");
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  &__image {
    width: 100%;
    text-align: center;
    a {
      display: inline-block;
    }
    img {
      width: 100%;
    }
  }
  &__caption {
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 $column-gutter/2;
    @media #{$medium-up} {
      width: 50%;
      margin: 0 auto;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    h2 {
      font-size: rem-calc(30);
      margin-bottom: $spacing * 0.5;
      line-height: 1.2;
      @media #{$medium-up} {
        font-size: rem-calc(50);
        margin-bottom: $spacing;
      }
      @media #{$large-up} {
        font-size: rem-calc(60);
      }
      @media #{$xxlarge-up} {
        font-size: rem-calc(77);
      }
      a {
        @include hover;
        color: #fff;
      }
    }
    p {
      line-height: 1.2;
      font-weight: normal;
      a {
        color: #fff;
      }
    }
  }
  .slick-dots {
    @include undolist;
    bottom: 0;
    padding: $spacing * 0.5 $spacing;
    background-color: transparent;
    text-align: center;
    @media #{$large-up} {
      bottom: $spacing;
    }
    li {
      margin: 0 $spacing 0 0;
      button {
        &:before {
          font-size: 14px;
          opacity: 0.5;
        }
      }
      &.slick-active button::before {
        color: #fff;
        opacity: 1;
      }
    }
  }
}

// Slider CNL
.slider {
  margin-bottom: $spacing;
  z-index: 2;
  @media screen and (min-width: 40em) {
    z-index: 0;
  }
  .slick-slide > div {
    height: $slider-h-sm;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    > div {
      display: flex;
    }
  }
  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: $slider-h-sm;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    &--1 {
      background-image: url("img/barcelona-buildings-city-1388030.jpg");
    }
    &--2 {
      background-image: url("http://picsum.photos/1500/500/");
    }
    &--3 {
      background-image: url("http://picsum.photos/1500/600/");
    }
    &--overlay {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.25);
      }

      &-1 {
        &:before {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
      &-2 {
        &:before {
          background-color: rgba(76, 5, 16, 0.6);
        }
      }
      &-3 {
        &:before {
          background-color: rgba(5, 33, 39, 0.6);
        }
      }
      &-4 {
        &:before {
          background-color: rgba(75, 26, 56, 0.6);
        }
      }
    }
  }
  &__caption {
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 $column-gutter/2;
    @media screen and (min-width: 40em) {
      width: 50%;
      margin: auto;
    }
    @media #{$medium-up} {
      width: 70%;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    h2 {
      font-size: rem-calc(25);
      margin-bottom: $spacing * 0.5;
      line-height: 1;
      font-weight: 700;
      @media #{$medium-up} {
        font-size: rem-calc(40);
        margin-bottom: $spacing;
      }
      @media #{$large-up} {
        font-size: rem-calc(50);
      }
      @media #{$xxlarge-up} {
        font-size: rem-calc(65);
      }
      a {
        @include hover;
        color: #fff;
      }
    }
    p {
      line-height: 1.2;
      font-weight: normal;
      a {
        color: #fff;
      }
    }
  }
  .slick-dots {
    @include undolist;
    bottom: 0;
    background-color: rgba(#000, 0.4);
    text-align: left;
    padding: $spacing * 0.5 $spacing;
    li {
      margin: 0 $spacing * 0.5 0 0;
      position: relative;
      top: -5px;
      button {
        &:before {
          font-size: 14px;
          opacity: 0.5;
        }
      }
      &.slick-active button::before {
        color: #fff;
        opacity: 1;
      }
    }
  }
}

// Slider galeria
.slider-galeria {
  @include undolist;
  margin: $spacing * 2 0;
  a {
    display: block;
    padding: 0 $spacing * 0.5;
  }
  img {
    margin: 0 auto;
  }
  .slick-list {
    margin: 0 $spacing * 1.5;
  }
  .slick-arrow:focus {
    outline: 3px solid $focus-color;
  }
  .slick-prev {
    left: 0;
    &:before {
      @include triangle(0.5em, #fff, right);
    }
  }
  .slick-next {
    right: 0;
    &:before {
      @include triangle(0.5em, #fff, left);
    }
  }
}

// Slider tipus 2 (alçada reduïda)
.slider--tipus2 {
  .slick-slide > div,
  .slider__img,
  .slider__image,
  .slider__image img {
    @media #{$height} {
      height: $slider-h-md;
    }
    @media #{$xlarge-up} {
      height: $slider-h-md;
    }
  }
  .slider__image img {
    margin-bottom: 0;
  }
}



.slider:not(.slick-slider) {
    &>div {
       display:none !important;
    }
    &>div:first-child {
        display: block !important;
	    height: $slider-h-sm !important;
	    @media #{$medium-up} {
	      height: $slider-h-md !important;
	    }
	    @media #{$height} {
	      height: $slider-h-lg !important;
	    }
	    &.slider__img {
		    display: flex !important;
		    flex-direction: column;
		    justify-content: center;
	    }
    }
}