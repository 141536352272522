.filters-form {
    fieldset {
        padding: $spacing;
        margin-bottom: $spacing * 2;
        background-color: #efefe9;
    }
    legend,
    label {
        @include visually-hidden;
    }
    fieldset {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing;
        vertical-align: middle;
        > div {
            margin-bottom: 0;
        }
        button {
            @media #{$medium-up} {
                margin-left: auto;
            }
        }
    }
    select {
        background: #fff !important;
    }
}
