.centre {
  p {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: $spacing * 0.5;
  }
  &__title {
    margin-bottom: $spacing * 0.5;
  }
  &__subtitle {
    text-transform: uppercase;
    margin-bottom: $spacing * 0.5;
    font-size: rem-calc(17);
    font-weight: bold;
  }
  &__info {
    margin-top: $spacing * 2;
    p {
      font-weight: 300;
    }
  }
  .btn {
    margin-bottom: 0;
  }
}
