.s {
  margin: 0 0 $spacing;
  @media screen and (min-width: 40em) {
    margin-bottom: 0;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    div {
      display: flex;
    }
  }
  legend {
    @include visually-hidden;
  }
  &:not(.s--jocs) {
    label {
      @include visually-hidden;
    }
  }
  input {
    flex: 1 1 auto;
    padding: $spacing * 0.5 0 $spacing * 0.5 $spacing * 0.5;
    border-radius: 6px 0 0 6px;
    appearance: none;
    background-color: #eee;
    background-image: linear-gradient(to bottom, #dadada, #eeeeee);
  }
  button {
    background-color: #eee;
    background-image: linear-gradient(to bottom, #dadada, #eee);
    padding: 0 $spacing * 0.5;
    border-radius: 0 6px 6px 0;
    @media screen and (min-width: 40em) {
      width: 40px;
      padding: 0;
    }
    img {
      margin: 0;
    }
  }

  &--cursos {
    margin-top: $spacing;
    @media screen and (min-width: 40em) {
      margin-top: 0;
    }
    input {
      background-image: linear-gradient(to bottom, #e7e7e7, #f7f7f7);
      border-radius: 6px;
      padding-right: $spacing;
      @media #{$medium-up} {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    button {
      background-image: none;
      background-color: #fff;
      border-radius: 0;
    }
  }
}
