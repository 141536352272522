.btn {
  display: inline-block;
  text-decoration: none;
  margin-bottom: $spacing;
  padding: rem-calc(8) $spacing * 1.5 rem-calc(7);
  text-align: center;
  color: #fff !important;
  transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out;
  font-size: rem-calc(14);
  font-weight: 900;
  border: 1px solid;
  @include themify($themes) {
    background-color: themed("btnColor");
    border-color: themed("btnColor");
  }
  @media #{$medium-up} {
    font-size: rem-calc(15);
    padding: rem-calc(7) $spacing * 1.5 rem-calc(6.5);
  }
  @media #{$large-up} {
    font-size: rem-calc(16);
    padding: rem-calc(6) $spacing * 1.5 rem-calc(6);
  }
  &:hover,
  &:active {
    text-decoration: none;
    background-color: #fff;
    @include themify($themes) {
      color: themed("btnColor") !important;
    }
  }
  &--sm {
    border-radius: 5px;
    font-weight: bold;
    padding: $spacing * 0.125 $spacing;
  }
  &--xs {
    font-size: rem-calc(12);
    padding: 0.125rem $spacing * 0.5;
  }
  &--invert {
    @include themify($themes) {
      color: themed("btnColor") !important;
    }
    background-color: #fff !important;
    border-color: #fff !important;
  }
  &--solid {
    background-color: #383838 !important;
    border-color: #383838 !important;
    border-radius: 6px;
    @media #{$large-up} {
      padding: rem-calc(13) $spacing * 2 rem-calc(13);
    }
    &:hover,
    &:focus {
      background-color: #fff !important;
    }
  }
}

// botons home CNLs
.h-btn {
  background-color: #fff;
  color: #000 !important;
  font-weight: 900;
  font-size: rem-calc(18);
  border-radius: 6px;
  border: 0;
  text-decoration: none;
  display: inline-block;
  padding: $spacing * 0.5 $spacing;
  margin-top: $spacing;
  @media #{$medium-up} {
    font-size: rem-calc(20);
    margin-top: $spacing * 1.5;
  }
  @media #{$large-up} {
    font-size: rem-calc(22);
    margin-top: $spacing * 2;
  }
  &:focus,
  &:hover {
    color: #000 !important;
    background-color: rgba(#fff, 0.8) !important;
  }
  &--sm {
    font-size: 1rem;
    padding-left: $spacing * 3;
    padding-right: $spacing * 3;
  }
}
