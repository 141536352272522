.pub-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: $spacing * 2;

  @media #{$large-up} {
    margin-bottom: $spacing * 4;
  }

  &__item {
    margin-bottom: $spacing;
    padding-bottom: $spacing;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
    &:before {
      display: none;
    }
  }
  h2 {
    margin-bottom: $spacing * 0.25;
    font-weight: bold;
    font-size: rem-calc(20);
    line-height: 1.1;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    a {
      @include hover;
      color: $body-font-color;
    }
  }
  time {
    font-weight: bold;
    display: block;
    font-size: rem-calc(12);
    margin-bottom: $spacing * 0.25;
    order: -1;
    text-transform: uppercase;
    color: $link-color;
  }
  p {
    margin-bottom: $spacing;
    font-size: rem-calc(16);
    font-weight: 300;
  }
  img {
    @media screen and (min-width: 40em) {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-bottom: 0;
  }
}
