.autocomplete-suggestions {
  border-top: 1px solid #d9d9d9;
  background: #fff;
  cursor: default;
  overflow: auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 4px;
}
.autocomplete-suggestion {
  background: url("/media/js/autocomplete/localitzacio.svg") $spacing * 0.5 50%
    no-repeat;
  background-size: 16px;
  color: #000;
  font-size: rem-calc(14);
  line-height: 30px;
  padding: 2px 5px 2px 25px;
  padding: $spacing * 0.125 $spacing $spacing * 0.125 $spacing * 1.5;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 1px solid #e6e6e6;
}
.autocomplete-suggestion span {
  font-size: rem-calc(12);
  color: #999;
  padding: 0 4px;
}
.autocomplete-no-suggestion {
  padding: 2px 5px;
}
.autocomplete-selected {
  background-color: #f0f0f0;
}
.autocomplete-suggestions strong {
  color: #000;
}
