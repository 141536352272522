.main-footer {
  text-align: center;
  font-weight: normal;
  padding: $spacing 0;
  @media #{$medium-up} {
    padding: $spacing * 2 0;
  }
  @media #{$large-up} {
    padding: $spacing * 3 0;
  }
}
.logo-path {
  @include themify($themes) {
    fill: themed("primaryColor");
  }
}
address {
  font-style: normal;
  margin-bottom: $spacing;
  p {
    font-size: rem-calc(14);
    margin-bottom: $spacing * 0.5;
    @media screen and (min-width: 40em) {
      font-size: rem-calc(16);
    }
  }
  span {
    display: block;
    @media screen and (min-width: 40em) {
      display: inline;
      &:before {
        content: "· ";
        display: inline;
        padding: 0 $spacing * 0.5;
      }
      &:first-of-type:before {
        display: none;
      }
    }
  }
}
.nom-centre {
  font-size: rem-calc(18);
  font-weight: 300;
  // text-transform: uppercase;
  margin-bottom: $spacing * 0.5;
  span {
    font-weight: 900;
  }
}
.horari {
  font-size: rem-calc(14);
  @media screen and (min-width: 40em) {
    font-size: rem-calc(16);
  }
  &:before {
    content: "";
    display: block;
    @include sprite("ic_schedule_24px");
    margin: 0 auto $spacing * 0.25;
    font-size: 1rem;
  }
}
.social {
  margin-bottom: 0;
  li {
    @media #{$medium-up} {
      padding-left: $spacing * 0.5;
      padding-right: $spacing * 0.5;
    }
  }
}
