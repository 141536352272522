.cpnl-link {
    display: none;
    @media screen and (min-width: 50em) {
        display: block;
        border-bottom: 1px solid #ccc;
        padding: $spacing 0;
        font-weight: bold;
        font-size: rem-calc(14);
        text-transform: uppercase;
        a {
            @include hover;
            // color: $cpnl-color-secondary;
        }
        svg {
            width: 25px;
            height: 19px;
            margin-right: $spacing * 0.25;
        }
    }
}
