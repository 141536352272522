.cookies {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 10;
  padding-top: $spacing;
  padding-bottom: $spacing;
  p {
    font-size: rem-calc(14);
    margin-bottom: 0;
    color: #fff;
    font-weight: 400;
    font-family: $font-family-sans-serif;
  }
  a {
    text-decoration: underline;
    white-space: nowrap;
    color: #fff;
    &:hover {
      text-decoration: none;
      border: 0;
    }
  }

  .btn,
  .btn:hover,
  .btn:focus {
    display: block;
    width: 8rem !important;
    margin: $spacing * 0.5 auto 0;
    border: 1px solid #fff;
    text-transform: none;
    padding: $spacing * 0.25;
    text-decoration: none;
    // background-color: #fff;
    // @include themify($themes) {
    //   color: themed("primaryColor");
    // }
  }
}
