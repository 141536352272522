table.dataTable {
  clear: both;
  margin: 0.5em 0 !important;
  max-width: none !important;
  width: 100%;
}
table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}
table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper {
  position: relative;
}
div.dataTables_wrapper div.dataTables_length label {
  float: left;
  text-align: left;
  margin-bottom: 0;
}
div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  margin-bottom: 0;
}
div.dataTables_wrapper div.dataTables_filter label {
  float: right;
  margin-bottom: 0;
}
div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 0;
  margin-left: 0.5em;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 2px;
}
div.dataTables_wrapper div.dataTables_paginate {
  float: right;
  margin: 0;
}
div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1rem 0;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 1.5rem;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
table.dataTable thead .sorting {
  background-image: url("img/sort_both.png");
}
table.dataTable thead .sorting_asc {
  background-image: url("img/sort_asc.png");
}
table.dataTable thead .sorting_desc {
  background-image: url("img/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
  background-image: url("img/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
  background-image: url("img/sort_desc_disabled.png");
}

div.dataTables_scrollHead table {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}


.dataTables_wrapper .dataTables_paginate .paginate_button {
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
    display: inline-block;
    color: #000;
    background-color: #f5f5f5;
    width: $spacing * 1.75;
    height: $spacing * 1.75;
    border-radius: 50%;
    text-align: center;
    margin: 0 $spacing * 0.25;
    line-height: 3;
    
    &.previous, &.next {
        text-indent: -99999;
      &:before {
        content: "";
        display: inline-block;
      }
    }
    &.previous:before {
        @include sprite("arrow-prev");
    }
    &.next:before {
        @include sprite("arrow-next");
    }
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff;
    @include themify($themes) {
      background-color: themed("paginationColor");
    }
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  background-color: transparent;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #fff;
    @include themify($themes) {
      background-color: themed("paginationColor");
    }
}
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    color: #fff;
    @include themify($themes) {
      background-color: themed("paginationColor");
    }
}