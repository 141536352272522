.h-block {
  margin: 0 0 $spacing;
  padding: $spacing * 1.5 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  background-color: #444;
  @media #{$medium-up} {
    padding: $spacing * 2.5 0;
  }
  @media #{$large-up} {
    height: rem-calc(532);
  }
  h2 {
    color: #fff;
    font-size: rem-calc(40);
    line-height: 1.1;
    &.light {
      font-weight: 300;
    }
    &.dark {
      font-weight: 700;
    }
    @media #{$medium-up} {
      font-size: rem-calc(60);
    }
    @media #{$large-up} {
      font-size: rem-calc(70);
    }
  }
  &--simple {
    justify-content: flex-start;
    @media #{$xlarge-up} {
      height: rem-calc(790);
    }
    h2 {
      color: #000;
      font-size: rem-calc(28);
      @media #{$medium-up} {
        font-size: rem-calc(30);
      }
      @media #{$large-up} {
        font-size: rem-calc(36);
      }
    }
  }
  &--color {
    &-1 {
      background-color: $block-color-1;
    }
    &-2 {
      background-color: $block-color-2;
    }
    &-3 {
      background-color: $block-color-3;
    }
  }
  &--foto {
    background-size: cover;
    background-repeat: no-repeat;
    &-1 {
      background-image: url("img/barcelona-buildings-city-1388030.jpg");
    }
    &-2 {
      background-image: url("http://picsum.photos/1500/500/");
    }
  }
  &--overlay {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &-1 {
      &:before {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    &-2 {
      &:before {
        background-color: rgba(76, 5, 16, 0.6);
      }
    }
    &-3 {
      &:before {
        background-color: rgba(5, 33, 39, 0.6);
      }
    }
    &-4 {
      &:before {
        background-color: rgba(75, 26, 56, 0.6);
      }
    }
  }
  &--img {
    padding: 0;
    @media #{$large-up} {
      height: auto;
    }
    img {
      margin-bottom: 0;
      width: 100%;
    }
  }
}

.idiomes {
  @include undolist;
  font-size: rem-calc(28);
  font-weight: 900;
  @media #{$medium-up} {
    column-count: 2;
    font-size: rem-calc(30);
  }
  @media #{$large-up} {
    column-count: 2;
    font-size: rem-calc(32);
  }
  li {
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      text-align: right;
      break-inside: avoid;
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: left;
      }
    }
  }
}
