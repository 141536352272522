.xxss {
  @include undolist;
  text-align: left;
  &__item {
    margin-bottom: 0;
    padding: 0 0 $spacing * 0.5 0;
    &--separador {
      border-top: 1px solid #979797;
      padding-top: $spacing * 0.5;
    }
    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
  }
  a {
    @include hover;
  }
  img {
    vertical-align: middle;
    margin: 0 $spacing * 0.5 0 0;
  }
}
.blogs-list {
  a {
    @include hover;
  }
}
