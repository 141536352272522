.map {
  width: 100%;
  height: 500px;
  .directori-fitxa & {
    margin-top: $spacing * 2;
    @media #{$large-up} {
      margin-top: $spacing * 3;
    }
  }
  img {
    margin-bottom: 0;
  }
  &.map__big {
    height: 600px;
  }
}

[data-a11y-toggle]:not([aria-controls]),
[aria-hidden="true"] {
  display: none;
}

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.map,
.collapsible {
  [aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: $spacing;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nivell {
  font-weight: bold;
  text-transform: uppercase;
  font-size: rem-calc(28);
  margin-bottom: 0;
  @media #{$medium-up} {
    font-size: rem-calc(32);
  }
  @media #{$large-up} {
    font-size: rem-calc(34);
  }
}
.mostrant {
  // text-transform: uppercase;
  color: $cpnl-color;
  font-size: 1rem;
  @include themify($themes) {
    color: themed("primaryColor");
  }
}
.alert {
  border: 1px solid #dbdbdb;
  padding: $spacing;
  text-align: center;
  a {
    color: $cpnl-color;
  }
}


.directori-centres-item {
	margin: 4rem 0 2rem;
	border-bottom: 1px solid #dbdbdb;
	padding-bottom: 2rem;
}
.troba-centre-interior {
  h1 {
    font-size: rem-calc(18);
    margin-bottom: $spacing;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    font-weight: 900;
  }
}

.checkbox-inline-list {
	label:not(.optional) {
	   display: inline;
	   margin-right: 1rem;
	   .control-indicator {
	       top: 0;
	   }
	}

	&.mt-1 {
	   margin-top: .75rem;
	}
}


.chronology {
	.btn {
	   font-weight: normal;
	   padding: .275rem 1.25rem .275rem;
	}
	.chronology--years {
	   display: none;
	   margin: 0 1rem 2rem;
	   &.slick-slider {
	       display: block;
	   }
	   .slick-next::before, .slick-prev::before {
	       color: #666;
	   }
	   .slick-arrow {
	       top: 1rem;
	   }
	   .slick-slide {
	       text-align: center;
	   }
	}
	.chronology--item {
	   margin-bottom:2rem;
	   padding-bottom:2rem;
	   border-bottom: 1px solid #ccc;
	   &:last-child {
	       border-bottom: none;
	   }
	   h2 {
		   font-size: 1.75rem;
	       font-weight: 900;
	   }
	   h3 {
	       margin-top: 0;
	   }
	}

	.chronology--nav {

       li {
            display:inline;
            margin: 0 5px;
            padding-left: 0;
            &:before {
                display: none;
            }
            a {
                color: #333;
                text-decoration: none;
            }
            &.prev {
                padding-left: 5px;
                a:before {
	                content : '';
	                border: solid #666;
	                border-width: 0 2px 2px 0;
	                display: inline-block;
	                padding: 5px;
				    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            }

            &.next {
                padding-right: 5px;
                a:after {
	                content : '';
	                border: solid #666;
	                border-width: 0 2px 2px 0;
	                display: inline-block;
	                padding: 5px;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }
            }
       }
	}
}
.houdiniForm div.buttons {margin-top: 1rem;}
img.full-width {width: 100%;}

p.subr {
    text-decoration: line-through;
}
p.tab {
    margin-left: 15px;
}
.anchor_offset:before {
    content: " ";
    display: block;
    height: 135px;
    margin-top: -135px;
    visibility: hidden;
}

.taulaBeneficis {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  li {
    a {
      color: #000
    }
    font-weight: 300;
    font-size: 14px;
    &::before {
      content: none;
    }
  }
}

.beneficis__poblacio {
  dl {
    dd {
      font-size: 14px;
      font-weight: bold;
      margin-left: 0;
    }
    dt{
      font-size: 14px;
    }
  }
}

.beneficis__columns {
  padding-left: 0 !important;
}


.benefici__equipament {
  padding: 25px;
  margin: 1.5rem 0;
  background-color: #f4f4f4;
}

.benefici__equipament ul {
  margin: 1rem 0 0;
  list-style-type: none;
  margin-left: 0;
}

.benefici__equipament ul li {
  padding-left: 0;
}

.benefici__equipament ul li:before {
  display: none;
}

.benefici__equipament ul li {
  // display: inline;
  // margin: 0 0 0 1.5rem;
  padding-right: 1.5rem;
  position: relative;
}

// .benefici__equipament ul li:after {
//   content: ' ';
//   display: block;
//   width: 1px;
//   height: 20px;
//   position: absolute;
//   top: 0;
//   right: 0;
//   background: #000;
// }

.benefici__equipament ul li:first-child {
  margin-left: 0;
}

.benefici__equipament ul li:last-child {
  margin-right: 0;
}

.benefici__equipament ul li:last-child:after {
  display: none;
}