.nav {
  margin-bottom: $spacing * 2;
  .theme-cnl &, .cercador-jocs & {
    margin-bottom: 0;
  }

  .js & {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    @media screen and (min-width: 40em) {
      position: static;
    }
  }
  .troba-curs &, .troba-centre &, .home &, .fitxa-gramatica & {
    margin-bottom: 0;
  }
  .gramatica & {
    margin-bottom: 2rem;
  }
}
.js-menu-open .nav {
  z-index: 3;
}
.menu-button {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: default;
  @include themify($themes) {
    background-color: themed("primaryColor");
  }
  .theme-cnl & {
    border-bottom: 20px solid $cnl-color;
  }
  .js-menu-open.theme-cnl & {
    border-bottom-color: #fff;
  }
  &.is-active {
    background-color: #fff;
    .hamburger-inner {
      &:before,
      &:after {
        background-color: #5f5f5f;
      }
    }
  }
  @media screen and (min-width: 40em) {
    display: none;
  }
}
.menu-wrapper {
  padding: $spacing 0;
  background-color: #fff;
  @media screen and (min-width: 40em) {
    padding: 0;
    margin-top: 0 !important;
    background-color: transparent;
  }
}
.site-menu {
  list-style: none;
  text-transform: uppercase;
  margin-bottom: 0;
  text-align: center;
  font-weight: bold;
  font-size: rem-calc(16);
  padding: $spacing $spacing * 3;
  @include themify($themes) {
    background-color: themed("navBgColor");
  }
  @media screen and (min-width: 40em) {
    font-size: rem-calc(14);
    position: relative;
    padding: $spacing 0 $spacing * 0.5 0;
    .theme-cnl & {
      clear: left;
    }
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      @include themify($themes) {
        background-color: themed("navBgColor");
      }
    }
    &:before {
      left: -100%;
    }
    &:after {
      right: -100%;
    }
  }
  @media #{$height} {
    .theme-cpnl & {
      padding-bottom: $spacing * 2;
      transition: padding-bottom 0.5s ease-out;
    }
    .sticky-nav & {
      padding-bottom: $spacing * 0.5;
    }
  }
  li {
    margin-bottom: $spacing * 0.5;
    padding-bottom: $spacing * 0.5;
    border-bottom: 1px solid;
    @include themify($themes) {
      border-bottom-color: themed("mobileNavBorder");
    }
    @media screen and (min-width: 40em) {
      display: inline-block;
      margin: 0 $spacing * 0.5 $spacing * 0.5;
      padding-bottom: 0;
      border: 0;
    }
    @media #{$large-up} {
      margin-left: $spacing * 1.25;
      margin-right: $spacing * 1.25;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      @media screen and (min-width: 40em) {
        margin-bottom: $spacing * 0.5;
      }
    }
  }
  a {
    text-decoration: none;
    @include themify($themes) {
      color: themed("navColor");
    }
    &:hover,
    &:focus {
      text-decoration: underline;
      @media #{$height} {
        &:before {
          transform: rotate(-0.125turn) scale(1.025);
        }
      }
    }
    &[aria-current="page"] {
      border-bottom: 1px solid;
    }
    .theme-cpnl & {
      @media #{$height} {
        display: block;
        &:before {
          transition: transform 0.15s ease-in-out;
          font-size: 1rem;
          content: "";
          display: block;
          @include sprite("ico-nav");
          margin-top: -$spacing * 2;
          margin-bottom: $spacing * 0.5;
        }
      }
    }
  }
}
.theme-cpnl.sticky-nav .site-menu a:before {
  @media (min-width: 75em) and (min-height: 44em) {
    display: none;
  }
}
