.l {
  &:before {
    content: "";
    display: block;
    margin-bottom: $spacing * 0.125;
    .cursos-form & {
      mix-blend-mode: color-dodge;
      font-size: 1rem;
      position: relative;
      top: 2px;
    }
  }
  &--01:before {
    @include sprite("l1");
  }
  &--02:before {
    @include sprite("l2");
  }
  &--03:before {
    @include sprite("l3");
  }
  &--04:before {
    @include sprite("l4");
  }
  &--05:before {
    @include sprite("l5");
  }
  &--06:before {
    @include sprite("l6");
  }
  &--07:before {
    @include sprite("l7");
  }
}
