@media #{$medium-up} {
  .js .nav--sticky {
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
    margin: 0 auto;
    padding: 0 $column-gutter * 0.5;
    max-width: 82rem;
    width: 100%;
    z-index: 3;
    .site-menu {
      a {
        color: #fff;
      }
      
      li.inici {
        display: none;
      }
    }
  }
  .sticky-nav {
    &.theme-cpnl {
      .site-menu {
        background-color: $cpnl-color;
        &:before,
        &:after {
          background-color: $cpnl-color;
        }
      }
    }
    &.theme-cnl {
      .site-menu {
        background-color: $cnl-color;
        &:before,
        &:after {
          background-color: $cnl-color;
        }
      }
    }
  }
}

// logo sticky
.site-menu li:first-child {
  display: none;
  @media #{$medium-up} {
    .sticky-nav & {
      display: block;
      float: left;
      margin: -0.5rem 0 0 0;
    }
  }
  img {
    margin-bottom: 0;
  }
}
