@charset "UTF-8";
/*Canvi per complir contrast accessibilitat*/
/*Canvi per complir contrast accessibilitat*/
/*Canvi per complir contrast accessibilitat*/
/*#ffbf47*/
.cursos-head > div {
  border-right: none; }

.section-destacats + .section-destacats {
  margin-top: -7.5rem; }

.section-destacats + .section-destacats {
  margin-top: -7.5rem; }

.houdiniForm.cursos-form + .houdiniForm.cursos-form {
  margin-top: -1.5rem; }

.image-gallery {
  margin-top: 5rem !important; }
  .image-gallery figure {
    margin-top: 1.5rem; }
    .image-gallery figure img {
      width: 100%; }

.mb-2 {
  margin-bottom: 2rem; }

.datepicker th.prev i::before {
  content: "←";
  display: block; }

.datepicker th.next i::before {
  content: "→";
  display: block; }

.datepicker td.active.day,
.datepicker td.active.year {
  color: #fff; }
  .theme-cpnl .datepicker td.active.day, .theme-cpnl
  .datepicker td.active.year {
    background-color: #c30c28; }
  .theme-cnl .datepicker td.active.day, .theme-cnl
  .datepicker td.active.year {
    background-color: #228386; }

.protecciodades .clausula {
  background: #fafafa;
  padding: 1.5rem 2rem;
  margin-top: 1.5rem;
  color: #555 !important; }
  .protecciodades .clausula h1,
  .protecciodades .clausula h2,
  .protecciodades .clausula h3,
  .protecciodades .clausula h4 {
    font-size: .875rem;
    margin: 0 0 1rem;
    color: #555 !important; }
  .protecciodades .clausula p,
  .protecciodades .clausula ul,
  .protecciodades .clausula ol {
    font-size: .75rem !important;
    margin: 0 0 0.5rem;
    color: #555 !important; }

.jocs .head .apartat {
  display: none; }

.jocs .head .page-title {
  position: relative;
  padding-right: 120px; }
  @media only screen and (min-width: 75em) {
    .jocs .head .page-title {
      padding-right: 330px; } }
  .jocs .head .page-title:after {
    content: "";
    background: url("../img/jocs_itujugues.png") no-repeat left top;
    background-size: 100%;
    height: 100px;
    width: 100px;
    display: block;
    position: absolute;
    top: -26px;
    right: 0px; }
    @media only screen and (min-width: 75em) {
      .jocs .head .page-title:after {
        right: 200px; } }

.sidebar-container {
  margin-top: 3rem;
  padding: 0.75rem 1.5rem; }
  .sidebar-container h2,
  .sidebar-container h3 {
    font-size: 120% !important; }
  .sidebar-container p,
  .sidebar-container ul,
  .sidebar-container li {
    font-size: 100% !important; }
  .sidebar-container .caixeta,
  .sidebar-container p.image {
    border-bottom: 2px solid #efefe9;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem; }
  .sidebar-container p.image img {
    width: 100%; }
  .sidebar-container #randomBlocs li::before {
    display: none; }

.caixaXXSS h4 {
  border-bottom: 1px solid #000;
  padding-bottom: 0.5rem; }
  .caixaXXSS h4 span {
    display: inline-block;
    margin-left: 8px;
    position: relative;
    bottom: 5px; }
  .caixaXXSS h4 a.more {
    display: none; }

.caixaXXSS p.usr {
  display: none; }

.caixaXXSS > div {
  padding: 1rem;
  max-width: 90%;
  margin: 0 auto; }
  .caixaXXSS > div p.image {
    margin-bottom: 0; }

.caixaXXSS a {
  text-decoration: none; }

.select.select--select2:after {
  display: none; }

.select .select2-container {
  width: 100%;
  border-radius: 6px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#dadada));
  background-image: linear-gradient(to bottom, #fff, #dadada);
  font-size: 1rem;
  color: #000;
  font-weight: 400;
  padding: 1rem 3rem 1rem 1.5rem;
  /* 		-webkit-box-sizing: border-box; */
  /* 		box-sizing: border-box;	 */ }
  .select .select2-container .select2-selection--single {
    height: auto;
    background-color: transparent;
    border: none; }
  .select .select2-container .select2-selection__arrow {
    top: 16px;
    right: 16px; }

.select2-search--dropdown,
.select2-results__option {
  padding: 0.75rem; }

.select2-results__options {
  font-size: 0.875rem; }

.comics .news__text {
  left: 30%;
  width: 70%; }

.comics .news__img {
  right: 70%;
  width: 30%; }

.tools__list {
  width: 80px; }

.tools__btn {
  margin-right: 20px; }
