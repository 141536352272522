
.white-popup {
  background: white;
  padding: $spacing;
  text-align: left;
  max-width: 600px;
  margin: $spacing * 2 auto;
  position: relative;
  @media screen and (min-width: 30em) {
    padding: $spacing * 1.5;
  }
  h2 {
    color: $cpnl-color;
    font-size: rem-calc(20);
  }
  ul {
    font-size: rem-calc(16);
    margin-bottom: 0;
    @media screen and (min-width: 30em) {
      column-count: 2;
    }
  }
  li {
    break-inside: avoid;
  }
}