// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
  font-weight: 300;
}
h1,
.t1 {
  .section--bg-4 & {
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    font-weight: 900;
    margin-bottom: $spacing;
    @media #{$large-up} {
      font-size: rem-calc(44);
      margin-bottom: $spacing * 2;
    }
  }
  .cercador & {
    font-weight: 300;
    line-height: 1;
    @media #{$large-up} {
      font-size: rem-calc(42);
    }
  }
}
h2,
.t2 {
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
  margin-top: $spacing * 2;
  margin-bottom: $spacing;
  .home & {
    font-weight: 900;
  }
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  &:first-child {
    margin-top: 0;
  }
  .section--bg-3 & {
    color: #fff;
  }
  .galeria-wrapper & {
    &:before {
      content: "";
      @include sprite("ic_camera_roll_24px");
      display: block;
      margin: 0 auto $spacing * 0.25;
      font-size: 1rem;
      opacity: 0.3;
    }
  }
}
h3,
.t3 {
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
  margin-top: $spacing * 2;
  margin-bottom: $spacing;
  @include themify($themes) {
    color: themed("titleColor");
  }
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  &:first-child {
    margin-top: 0;
  }
}
h4,
.t4 {
  color: #000 !important;
  font-size: rem-calc(16);
  font-weight: bold;
  margin-bottom: 0.75rem; /*$spacing;*/
  line-height: 1.2;
}
p,
ol,
ul,
dl,
span {
  font-size: rem-calc(16);
  line-height: 1.6;
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }

  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.5;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.5;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

p,
ol,
ul,
dl,
figure,
img,
form,
hr,
table {
  margin: 0 0 $spacing;
  // font-size: rem-calc(15);
}
ul {
  list-style: none;
  font-weight: normal;
  main & {
    li {
      position: relative;
      padding-left: $spacing;
      margin-bottom: $spacing * 0.25;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        height: $spacing * 0.5;
        width: $spacing * 0.5;
        position: absolute;
        left: 0;
        top: 0.5em;
        @include themify($themes) {
          background-color: themed("klandColor");
        }
      }
      ul {
        margin-top: $spacing * 0.5;
        color: #3f3f3f;
      }
      li {
        font-size: rem-calc(14);
        padding-left: $spacing * 0.75;
        &:before {
          height: $spacing * 0.25;
          width: $spacing * 0.25;
          top: 0.6em;
        }
      }
    }
  }
}
ul.medium-block-grid-3 {
  li {
    padding-bottom: 0 !important;
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }
}

// llista simple, sense klanders
ul,
ol {
  &.simple-list {
    list-style: none;
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
}

ol {
  padding-left: $spacing;
}

.dl-inline {
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dt {
    float: left;
    margin-right: 0.3em;
  }
}

figure {
  img {
    margin-bottom: 0;
  }
}
figcaption {
  font-size: rem-calc(12);
  margin-top: $spacing * 0.5;
}

::selection {
  color: #fff;
  @include themify($themes) {
    background-color: themed("linkColor");
  }
}
strong,
b {
  font-weight: 600;
}
// em,
// i {
// }

.upp {
  text-transform: uppercase;
}

// links underline
a {
  @include themify($themes) {
    color: themed("linkColor");
  }
  &:hover {
    text-decoration: none;
  }
  .section--bg-4 & {
    color: #fff;
  }
}
a,
button,
input,
textarea {
  &:focus {
    outline: 3px solid $focus-color;
  }
}

p.dest,
.dest p {
  font-weight: 300;
  font-size: rem-calc(20);
  text-align: center;
  line-height: 1.4;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(24);
  }
  &:before {
    content: "“";
    display: block;
    margin: 0 auto $spacing;
    width: $spacing * 3;
    height: $spacing * 3;
    height: $spacing * 3;
    border-radius: 50%;
    font-size: rem-calc(75);
    color: #fff;
    font-weight: normal;
    text-align: center;
    @include themify($themes) {
      background-color: themed("primaryColor");
    }
  }
  strong {
    font-weight: bold;
  }
}

p.dest-2,
.dest-2 p {
  font-weight: 300;
  font-size: rem-calc(20);
  text-align: center;
  line-height: 1.4;
  border: 4px solid;
  padding: $spacing;
  @include themify($themes) {
    border-color: themed("destBorder");
  }
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(24);
  }
  strong {
    font-weight: bold;
  }
}

.dest-3 {
  text-align: center;
  margin-bottom: $spacing * 2;
  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem-calc(18);
    margin-bottom: $spacing * 0.75;
    color: #000 !important;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  img {
    margin-bottom: $spacing * 0.5;
  }
  p {
    font-size: rem-calc(16);
    margin-bottom: $spacing * 0.75;
  }
  .btn {
    margin-bottom: 0;
  }
  &__img {
    text-align: center;
    img,
    svg {
      margin: auto auto 1rem auto;
      width: 50%; /*ie fix*/
      max-width: 120px;
      vertical-align: middle;
      path:not([fill="none"]),
      circle:not([fill="none"]),
      ellipse:not([fill="none"]),
      line:not([fill="none"]),
      polygon:not([fill="none"]),
      rect:not([fill="none"]) {
        fill: #ff7a8e !important;
      }
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid #cccccc;
  .section--bg-4 & {
    border-top-color: #fff;
  }
}
table {
  width: 100%;
  font-size: rem-calc(16);
  th,
  td {
    padding: $spacing * 0.5 $spacing * 0.75;
    vertical-align: top;
  }
  th {
    font-weight: bold;
    text-align: left;
  }
  td {
    background-color: #efefe9;
    font-weight: 300;
  }
  thead th {
    color: #fff;
    @include themify($themes) {
      background-color: themed("primaryColor");
    }
  }
  tbody {
    th {
      vertical-align: top;
      background-color: #efefe9;
    }
    th,
    td {
      border-top: 10px solid #fff;
    }
    tr > *:last-child {
      border-right: 0;
    }
  }
  &.striped {
    a {
      color: $cpnl-color-secondary;
      text-decoration: none;
    }
    td {
      background-color: transparent;
    }
    thead th {
      color: $body-font-color;
      background-color: #efefe9;
      text-align: center;
      // border-left: 1px solid #979797;
      // &:first-child {
      //   border-left: 0;
      // }
      // &:last-child {
      //   border-right: 1px solid #979797;
      // }
    }
    img {
      margin-bottom: 0;
      max-width: none;
    }
    tbody {
      tr:not(.expandable-row) a {
        padding: 0 $spacing * 0.5;
      }
      th {
        background-color: transparent;
        font-weight: normal;
        vertical-align: middle;
        min-width: 18rem;
        @media #{$medium-up} {
          min-width: auto;
        }
      }
      td {
        text-align: center;
        font-weight: bold;
        font-size: 2rem;
        line-height: 1;
        // border-left: 1px solid #979797;
        // &:last-child {
        //   border-right: 1px solid #979797;
        // }
      }
      th,
      td {
        border-top: 0;
      }
      tr.has-bg {
        th,
        td {
          background-color: #efefe9;
        }
      }
    }
    span[aria-hidden="true"] {
      display: inline-block;
    }
    .row {
      margin-left: -$spacing * 0.75;
      margin-right: -$spacing * 0.75;
    }
  }
  button {
    background-color: transparent;
    padding: 0;
    color: $cpnl-color-secondary;
    width: 100%;
    text-align: left;
    &:after {
      content: "+";
      float: right;
      font-weight: normal;
      font-size: rem-calc(18);
    }
    &:hover {
      text-decoration: underline;
    }
    &[aria-expanded="true"] {
      font-weight: bold;
      color: $body-font-color;
      // @media #{$medium-up} {
      //   font-size: rem-calc(18);
      // }
      &:after {
        content: "-";
      }
    }
  }
}
.expandable-row {
  display: none;
}
.expandable-row[aria-hidden="false"] {
  display: table-row;
  border-top: $spacing * 0.5 solid transparent;
  * {
    font-size: 1rem;
  }
}
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
.inline-list {
  @include undolist;
  font-size: 0;
  margin-bottom: 0;
  margin-left: -$spacing * 0.5;
  margin-right: -$spacing * 0.5;
  li {
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin: 0 $spacing * 0.5 $spacing;
    &:last-child {
      margin-bottom: $spacing;
    }
  }
  &--logos {
    li {
      margin: 0 $spacing $spacing * 2;
      &:last-child {
        margin-bottom: $spacing * 2;
      }
    }
  }
  img {
    margin-bottom: 0;
  }
}
.intro {
  font-weight: 300;
  font-size: rem-calc(18);
  line-height: 1.4;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$large-up} {
    font-size: rem-calc(24);
  }
}
