.circles-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    font-size: rem-calc(18);
    justify-content: center;
  }
  &__item {
    margin: 0 0 $spacing * 1.5;
    padding: 0 $column-gutter * 0.5;
    width: 100%;
    text-align: center;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media #{$medium-up} {
      width: 33.33%;
    }
    > a {
      @include hover;
      display: block;
      color: #000;
    }
  }
  &__title {
    font-size: rem-calc(20);
    font-weight: 900;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    a {
      text-decoration: none;
      color: #fff;
      padding: $spacing;
      margin: 0 auto $spacing * 0.75;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 50%;
      width: $spacing * 8;
      height: $spacing * 8;
      line-height: 1.3;
      @include themify($themes) {
        background-color: themed("circleColor");
      }
      @media #{$medium-up} {
        width: $spacing * 9;
        height: $spacing * 9;
      }
      &:hover,
      &:focus {
        background-color: #272727;
      }
    }
  }
  &__image {
    margin: 0 auto $spacing * 0.75;
    border-radius: 50%;
    width: $spacing * 8;
    height: $spacing * 8;
    max-width: none;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    @media #{$medium-up} {
      width: $spacing * 9;
      height: $spacing * 9;
    }
  }
  &__subtitle {
    font-size: rem-calc(18);
    font-weight: bold;
    margin-bottom: $spacing * 0.25;
    line-height: 1.2;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  &__text {
    font-size: rem-calc(14);
    font-weight: 300;
    margin-bottom: 0;
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
  }
  a {
    &:hover,
    &:focus {
      img {
        opacity: 0.9;
      }
    }
  }
}
