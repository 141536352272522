.results-list {
  @include undolist;
  border-top: 1px solid #abab9e;
  li {
    border-bottom: 1px solid #abab9e;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    padding: $spacing 0;
  }
  h2 {
    font-size: rem-calc(18);
    line-height: 1.2;
    margin: 0;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  a {
    color: $cpnl-color;
    @include hover;
  }
  time {
    display: block;
    font-size: rem-calc(14);
    font-weight: bold;
    margin-bottom: $spacing * 0.5;
  }
}

.cercador.list {
	article {
	   border-bottom: 1px solid #abab9e;
	   margin-top: 1rem;
	   margin-bottom: 1rem;
	   
	   &:last-child{
           border-bottom: 0;
       }
	}
	.breadcrumbs {
	   margin-bottom: .5rem;
	   font-size: 14px;
	   font-weight: 600;
	   li {
	       display: inline;
	       padding:0 .5rem 0 0;
	       &:first-child {
	           display: none;
	       }
	       &:before {
	           display:none;
	       }
	       &:after {
               content : '›';
               margin-left: .5rem;
               font-size: 18px;
           }
           &:last-child{
              &:after {
                display: none;
              }
           }
	       a {
	           text-decoration: none;
	       }
	   }
	}
	
	h2.cerca-titol-element {
	   margin: 0 0 1rem;
	}
}
