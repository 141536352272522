.selector-cnl {
  text-align: center;
  margin-bottom: $spacing;
  @media screen and (min-width: 40em) {
    text-align: left;
    float: left;
    margin: rem-calc(44) 0 0 $spacing;
    .theme-cpnl & {
        margin-top: 0;
        margin-left: 0;
        margin-right: $spacing;
    }
  }
  @media #{$medium-up} {
    margin-top: rem-calc(40);
    .theme-cpnl & {
        margin-top: 0;
    }
  }
  
  button {
    font-size: rem-calc(12);
    font-weight: bold;
    background-color: #ebebeb;
    border-radius: 5px;
    padding: $spacing * 0.25 $spacing * 0.75;
    @media screen and (min-width: 40em) {
      padding: $spacing * 0.125 $spacing * 0.75;
    }
    &:after {
      content: "";
      font-size: 1rem;
      display: inline-block;
      @include sprite("arrow-cnl-selector");
      position: relative;
      top: -1px;
      margin-left: $spacing * 0.25;
    }
  }
  ul {
    font-size: rem-calc(14);
    background-color: #fff;
    margin-top: $spacing * 0.25;
    @media screen and (min-width: 40em) {
      padding: 0 $spacing * 0.5 $spacing * 0.5;
      margin-bottom: 0;
      position: absolute;
      z-index: 2;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    }
    @media screen and (min-width: 50em) {
      padding-top: $spacing * 0.5;
      column-count: 2;
      .theme-cpnl & {
        right: 10px;
        min-width: 500px;
      }
    }
    @media #{$large-up} {
      column-count: 3;
      .theme-cpnl & {
        min-width: 800px;
      }
    }
    .sticky-nav & {
      @media #{$medium-up} {
        display: none;
      }
    }
    li {
      margin-bottom: $spacing * 0.5;
    }
    a {
      color: $body-font-color;
      @include hover;
    }
  }
}

// AppendAround
.selector-cnl-small {
  @media screen and (min-width: 40em) {
    display: none;
  }
}
.selector-cnl-large {
  display: none;
  @media screen and (min-width: 40em) {
    display: block;
  }
}
