ul.duesCol,
ol.duesCol {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 30em) {
      width: 46%;
      margin-left: 2% !important;
      margin-right: 2% !important; 
    }
  }
}


ul.llista_fons,
ol.llista_fons {
    background-color: #efefe9;	
    padding: 2rem !important;
	li {
	    margin: 0 0 $spacing;
/* 	    padding: 0 $spacing * 0.25; */
	    &:before {
/* 	      display: none; */
	    }
        &:last-child {
          margin-bottom: 0;
        }
  }
}

ul.llista_separadors,
ol.llista_separadors {
    padding: 1rem;
    margin: 1rem 0;
    li {
		margin: 0 0 1rem;
		border-bottom: 3px solid #efefe9;
		padding: 0 0 1rem;
        &:before {
           display: none;
        }
        &:last-child {
          margin-bottom: 1rem;
        }
  }
  &.llista_fons {
    li {
        border-bottom: 3px solid #fff;
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
    }
    
  }
}
ol.llista_fons,
ol.llista_separadors {
	list-style-position: inside;
}

ul.llista-botons,
ol.llista-botons {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  li {
    width: 100%;
    padding-left: 0;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    @media screen and (min-width: 40em) {
      width: 46%;
      margin-left: 2% !important;
      margin-right: 2% !important; 
    }
        &:before {
       display: none;
    }
  }
  a {
    border: 2px solid #EC6264;
    border-radius: 4px;
    display: block;
    padding: $spacing * .25 $spacing;
    text-decoration: none;
    margin-bottom: 1.5rem;
  }
}

