.rel {
  background-color: #efefe9;
  padding: $spacing;
  margin-bottom: $spacing;
  .section--bg-1 & {
    background-color: #fff;
  }
  h2 {
    font-size: rem-calc(14);
    text-transform: uppercase;
    margin-bottom: $spacing;
  }
  ul {
    @include undolist;
    margin-bottom: 0;
    font-size: 1rem;
  }
  li {
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
    padding: $spacing * 0.5 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}