.tabbed {
  margin-bottom: $spacing;
}
.tab-menu {
  padding: 0;
  margin-left: 0;
  font-size: 0;
  margin-bottom: 0;
  li {
    padding: 0;
    margin: 0 $spacing * 0.5 0 0;
    display: inline-block;
    &:before {
      display: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    text-decoration: none;
    display: inline-block;
    font-size: rem-calc(16);
    font-weight: normal;
    color: $body-font-color;
    background-color: #e2e2d8;
    padding: $spacing * 0.5 $spacing;
    &:hover,
    &[aria-selected] {
      outline: 0;
      background-color: #efefe9;
    }
    &:focus {
      outline: 1px dotted black;
    }
  }
}

.panel {
  outline: none;
  background-color: #efefe9;
  padding: $spacing;
  @media #{$medium-up} {
    padding: $spacing * 1.5;
  }
  @media #{$large-up} {
    padding: $spacing * 2;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $body-font-color !important;
    margin-top: 0;
    margin-bottom: $spacing * 0.5;
  }
  h2 {
    font-size: rem-calc(18);
    font-weight: bold;
    border-bottom: 1px solid #000;
    border-bottom-color: rgba(0, 0, 0, 0.125);
    padding-bottom: $spacing * 0.25;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
  }
  h3 {
    font-size: rem-calc(16);
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    @media #{$large-up} {
      font-size: rem-calc(20);
    }
  }
  h4 {
    font-size: rem-calc(16);
    font-weight: normal;
    text-transform: uppercase;
  }
  h5 {
    font-size: rem-calc(16);
    font-weight: bold;
  }
  h6 {
    font-size: rem-calc(16);
    font-weight: normal;
  }
  p,
  ul,
  ol {
    font-size: rem-calc(16);
    margin-bottom: $spacing * 0.5;
    font-weight: 300;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 40em) {
  .tab-menu {
    li {
      display: block;
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
      margin-top: 1px;
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      display: block;
      padding: $spacing * 0.25 $spacing * 0.5;
    }
    [aria-selected] {
      outline: 0;
    }
  }
}
.tabbed {
  &--v {
    @media screen and (min-width: 40.0625em) {
      display: flex;
      .tab-menu {
        flex-basis: 25%;
        li {
          display: block;
          margin: 0 0 $spacing * 0.5 0;
        }
        a {
          display: block;
        }
      }
    }
  }
}

.tabbed-links-establiments {
    margin-bottom: $spacing * 1.5;
    
    .tab-menu {
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #c1c1c1 160%);
    }
    
    a {
        font-size: rem-calc(20);
        padding: $spacing * .5 $spacing * 1.125 ;
        color: #565656;
        font-weight: bold;
        background-color: #e2e2d8;
        border: 1px solid#000000;
        @media (min-width: 40em) {
            border-bottom: 1px solid#e2e2d8;
            margin-right: $spacing * .75;
        }
    }    
    li.current {
        a {
            color: #fff;
            background-color: #C30C28;
            border: 1px solid#C30C28;
        }
    }
    

}