.breadcrumb-nav {
    ol {
        list-style: none;
        padding-left: 0;
        font-size: 0.875rem;
        font-weight: normal;
        li {
            display: inline-block;
            &::after {
                content: "";
                display: inline-block;
                width: 4px;
                height: 8px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNCIgaGVpZ2h0PSI4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICA8ZGVmcz4KICAgIDxwYXRoIGlkPSJhIiBkPSJNMCAwaDR2Ny4yMTNIMHoiLz4KICA8L2RlZnM+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxtYXNrIGlkPSJiIiBmaWxsPSIjZmZmIj4KICAgICAgPHVzZSB4bGluazpocmVmPSIjYSIvPgogICAgPC9tYXNrPgogICAgPHBhdGggZD0iTTMuODg0IDMuMzI3LjY3NC4xMTdBLjM5NS4zOTUgMCAwIDAgMCAuMzk0djYuNDIyYzAgLjM1Mi40MjUuNTI4LjY3NC4yOGwzLjIxLTMuMjEyYS4zOTUuMzk1IDAgMCAwIDAtLjU1OCIgZmlsbD0iIzAwMCIgbWFzaz0idXJsKCNiKSIvPgogIDwvZz4KPC9zdmc+");
                margin: 0 1.5rem;
            }
            &:last-child::after {
                display: none;
            }
        }
        span {
            font-size: inherit;
        }
        a {
            @include hover;
            @include themify($themes) {
                color: themed("primaryColor");
            }
        }
    }
    .theme-cnl &, .cercador-jocs & {
        margin-top: 40px;
    }
}
