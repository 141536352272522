.jocs-list {
  @include undolist;
  li {
    margin-bottom: 0;
  }
}
.joc {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: $spacing;
  position: relative;
  img {
    margin-right: auto;
  }
  &__info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 0 !important;
  }
  &__wrapper {
    flex-grow: 1;
    padding-left: $spacing * 0.5;
    margin-bottom: 0 !important;
  }
  &__title {
    font-size: rem-calc(16);
    font-weight: bold;
    margin-bottom: $spacing * 0.25;
    a {
      color: $body-font-color;
      @include hover;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  &__image {
    & img {
        max-height: 250px;
    }
    &_view {
        & img {
            max-width: 100%;
            min-width: 400px;
        }
    }
  }
  &__novetat {
	position: absolute;
	right: 20px;
	top: 0;
	background: red;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	// font-size: 12px !important;
  font-size: .75rem !important;
	display: inline-block;
	padding: 20px 7px;
	color: #fff;
	font-weight: bold;
  }
  &__marca {
    // text-transform: uppercase;
    font-weight: bold;
    font-size: rem-calc(12);
    color: $cpnl-color;
    margin-bottom: 0;
  }
  &__categ {
    font-size: rem-calc(14);
    font-style: italic;
    margin-bottom: 0;
  }
  &__edat {
    color: #fff;
    background-color: $cpnl-color-secondary;
    margin-bottom: auto;
    border-radius: 50%;
    text-align: center;
    width: $spacing * 2;
    height: $spacing * 2;
    font-size: rem-calc(10);
    font-weight: normal;
    line-height: 1.1;
    flex-shrink: 0;
    span {
      display: block;
      font-size: rem-calc(15);
      font-weight: 900;
      margin-top: 0.6em;
    }
  }
}

.fitxa--jocs {
	.fitxa__title {
        margin-right: 60px;
    }

	.descripcio {
	   p {
	   font-size: 1.125rem;
	   }
	}

	dl {
	   font-size: 1rem;
	    dd {
/* 	       text-transform: uppercase; */
	    }
	    .categoria {
	       font-weight: bold;
	       font-style: italic;
	    }
	    .genere {
           font-weight: bold;
        }
        .categoria {
           font-weight: bold;
        }
        .jugadors {
            text-transform: none;
        }
	}
	.joc__fabricant {
	   margin-bottom: 2rem;

	   @media screen and (min-width: 30em) {
		   float:right;
	       margin-left: 2rem;
	       max-width: 200px;
       }

	   .joc__fabricant_wrapper {
		   text-align: center;

	       img {
	           max-width: 100px;
	           margin-bottom: 1rem;
	       }
	       span {
	           display: block;
	           font-size: rem-calc(12);
	           font-weight: bold;
	           color: $cpnl-color;
	       }
		}

	}
}
.puntuacio {
	img {
	    max-width: 30px;
	    margin-top: 8px;
	}
}
