.applied-filters {
  background: #ebebeb;
  padding: 1rem 2rem;
  margin: -1rem 0 1.75rem 0 !important;
  p {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: $spacing * 0.5;
  }
  ul {
    margin: 0 0 1rem;
    padding: 0;
    li{
        background: #fff;
        display: inline-block;
        margin: 0  .75rem .5rem 0;
        padding: .25rem .5rem;
        padding-right: 28px;
        font-size: em-calc(12);
        position: relative;
        
        &:before {
            display: none;
        }
        
        .close {
		  position: absolute;
		  right: 5px;
		  top: 5px;
		  width: 15px;
		  height: 15px;
		  opacity: 0.3;
			&:hover {
			  opacity: 1;
			}
			&:before, &:after {
			  position: absolute;
			  right: 5px;
			  content: ' ';
			  height: 16px;
			  width: 2px;
			  background-color: #333;
			}
			&:before {
			  transform: rotate(45deg);
			}
			&:after {
			  transform: rotate(-45deg);
			}        
			
			span {
			 display: none;
			}
        }
    }
  }
}
