.table-blocs {
    td {
        border-top: 0;
        border-bottom: 2px solid #fff;
    }
    tr.separate td {
        border-top: 10px solid #fff;
    }
    
    td.centre {
        font-weight: bold;
    }
    
    td.cat {
        text-align:center;
        span {
            position: relative;
		    display: inline-block;
		    width: 30px;
		    height: 30px;
		    &:before {
			    position: absolute;
			    left: 0;
			    top: 50%;
			    height: 50%;
			    width: 3px;
			    background-color: #ff4e69;
			    content: "";
			    transform: translateX(10px) rotate(-45deg);
			    transform-origin: left bottom;
			}
			&:after {
			    position: absolute;
			    left: 0;
			    bottom: 0;
			    height: 3px;
			    width: 100%;
			    background-color: #ff4e69;
			    content: "";
			    transform: translateX(10px) rotate(-45deg);
			    transform-origin: left bottom;
			}
        }
    }
}
.table-responsive {
    max-width: 100%;
    overflow-x: auto;	
}
