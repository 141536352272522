.pagination {
  list-style: none;
  text-align: center;
  font-size: 1rem;
  // padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  background-color: #efefe9;
  padding: $spacing;
  display: flex;
  flex-wrap: wrap;
  gap: $spacing;
  justify-content: center;
  font-weight: normal;
  li {
    padding: 0;
    // display: inline-block;
    // font-size: rem-calc(16);
    // margin-bottom: $spacing * 0.5;
    &:before {
      display: none;
    }
  }
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    background-color: #fff;
    width: $spacing * 1.75;
    height: $spacing * 1.75;
    border-radius: 50%;
    text-align: center;
    // margin: 0 $spacing * 0.25;
    // line-height: 3;
  }
  .current a,
  a:hover,
  a:focus {
    color: #fff;
    @include themify($themes) {
      background-color: themed("paginationColor");
    }
  }
  .unavailable {
    a {
      width: auto;
      background-color: transparent;
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }
  .prev,
  .next {
    a {
      background-color: transparent;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      &:before {
        content: "";
        display: block;
        width: 7px;
        height: 12px;
      }
      &:hover,
      &:focus {
        background-color: #fff;
      }
      &:not([href]) {
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }
  .prev {
    a {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNyAxLjQxIDIuNjczIDYgNyAxMC41OSA1LjY2OCAxMiAwIDZsNS42NjgtNnoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPgo8L3N2Zz4=");
      }
    }
  }
  .next {
    a {
      &:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMCAxMC41OSA0LjMyNyA2IDAgMS40MSAxLjMzMiAwIDcgNmwtNS42NjggNnoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPgo8L3N2Zz4=");
      }
    }
  }
}
