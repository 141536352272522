.alert {
  margin-bottom: $spacing * 1.5;
  padding: $spacing;
  @media #{$medium-up} {
    padding: $spacing * 1.5;
  }
  &-1 {
    @extend .alert;
    @extend .alert; // està fet així i no amb dues classes (.alert .alert--1) per facilitar aplicar estils a l'editor visual
    background-color: #ffbe54;
    .alert__title {
      color: #c30c28;
    }
  }
  &-2 {
    @extend .alert; // està fet així i no amb dues classes (.alert .alert--2) per facilitar aplicar estils a l'editor visual
    background-color: #c30c28;
    .alert__title,
    p {
      color: #ffbe54;
    }
  }
  &-3 {
    @extend .alert; // està fet així i no amb dues classes (.alert .alert--2) per facilitar aplicar estils a l'editor visual
    background-color: #e2e2d8;
    text-align: left !important;
    font-size: rem-calc(16);
    .alert__title,
    p {
      color: #000;
    }
  }
  &__title {
    font-size: rem-calc(20);
    text-transform: uppercase;
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
  }
  p {
    margin-bottom: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
