.directori-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
  li {
    margin: 0;
    display: flex;
    padding: 0 $spacing * 0.25 $spacing * 2;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media screen and (min-width: 50em) {
      width: 33.33%;
      padding-bottom: $spacing * 3;
    }
  }
}
