.h-pub-list {
  margin-left: -$spacing * 0.5;
  margin-right: -$spacing * 0.5;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  &__item {
    margin: 0 0 $spacing;
    padding: 0 $spacing * 0.5;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0 0 $spacing;
    }
    @media screen and (min-width: 35em) {
      width: 50%;
    }
    @media screen and (min-width: 55em) {
      flex-direction: row-reverse;
      width: 33.333%;
    }
  }

  &__wrapper {
    @media screen and (min-width: 55em) {
      flex: 1;
    }
  }

  &__title {
    margin-bottom: $spacing * 0.25;
    font-size: rem-calc(18);
    color: #000 !important;
    hyphens: auto;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
  }
  p {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: $spacing * 0.5;
  }
  img {
    margin-right: auto;
    margin-bottom: $spacing;
    @media screen and (min-width: 55em) {
      margin-right: $spacing * 0.75;
      margin-bottom: auto;
    }
  }
}
