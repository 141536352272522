.nivells-grm {
    display: flex;
    flex-wrap: wrap;
    @media #{$medium-up} {
        flex-wrap: nowrap;
    }

    div {
        position: relative;
        margin: 0 $spacing * 0.25 $spacing * 2 0;
        align-self: flex-end;
        @media #{$medium-up} {
            flex: 110px;
        }
        &.n1, &.n2, &.n3 {
            background-color: #f38d17;
            height: 164px;
        }
        &.n4, &.n5, &.n6 {
            background-color: #d91016;
            height: 176px;
        }
        &.n7, &.n8, &.n9 {
            background-color: #b31916;
            height: 188px;
        }
        &.n10, &.n11, &.n12 {
            background-color: #d93a7c;
            height: 200px;
        }
        &.n13 {
            background-color: #af3060;
            height: 212px;
            span {
                &.num {
                    bottom: 3px;
                    @media #{$medium-up} {
                        font-size: rem-calc(20);
                    }
                }
            }
        }
        p {
            margin:0;
            width: 100%;
            height: 100%;
            a {
                color: white;
                text-decoration: none;
                display: block;
                width: 100%;
                height: 100%;
                span {
                    &.nom {
                        writing-mode: vertical-lr;
                        transform: rotate(180deg);
                        /*@media #{$medium-up} {
                            font-size: rem-calc(32);
                        }*/
                        font-size: rem-calc(30);
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                    }
                    &.num {
                        font-weight: bold;
                        position: absolute;
                        right: 10px;
                        bottom: 2px;
                        font-size: rem-calc(30);
                        /*@media #{$medium-up} {
                            font-size: rem-calc(50);

                        }*/
                    }
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }

}
.dest-grm {
    text-align: right;
}

/* Fitxa */
.fitxa-gramatica {

	table.border-table {
		border-collapse: unset;

		tbody th {
			border-bottom: 2px solid #fff;
			border-top: none;
		}
	}
    &.theme-cpnl {
        h3 {
            font-weight: bold;
            font-size: rem-calc(16);
            border-bottom: 1px solid #979797;
            margin-bottom: $spacing * 0.5;
            padding-bottom: $spacing * 0.5;
            width: 100%;
            text-transform: uppercase;
        }
        #filtres {
            @media #{$medium-up} {
                top: -150px;
            }
            p, li, h3, button {
                color: white;
            }
            .results-grm {
                .fitxa-nivell {
                    a {
                        color: #BBBBBB;
                    }
                }
                padding: $spacing * 0.25 0;
                font-size: rem-calc(14);
                a {
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }

                }
           }
           .cursos-form {
                /*padding-bottom: 150px;*/
                background: #4C4C4C;
                .collapsible {
                    border: 0;
                    .collapsible__title {
                        button {
                            border: 0;
                            padding: 0;
                            margin-top: $spacing * 2;
                            cursor: pointer;
                            &:focus {
                                outline: 0;
                            }
                            div {
                                 position: relative;
                                 &:after {
                                     right: 1px;
                                     bottom: -3px;
                                     content: "+";
                                     position: absolute;
                                 }
                            }
                            &[aria-expanded="true"] {
                                div {
                                    &:after {
                                     content: "-";
                                    }
                                }
                            }
                        }
                    }
                }
                &:before {
                    @media #{$medium-up} {
                        background: #4C4C4C;
                    }
                }
           }
        }
        #filtresNivells {
            p, li, h3, button {
                color: white;
            }
            .results-grm li, p {
                padding: $spacing * 0.25 0;
                font-size: rem-calc(14);
                a {
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
           }
           .cursos-form {
                /*padding-bottom: 150px;*/
                background: #4C4C4C;
                .collapsible {
                    border: 0;
                    .collapsible__title {
                        button {
                            border: 0;
                            padding: 0;
                            margin-top: $spacing * 2;
                            cursor: pointer;
                            &:focus {
                                outline: 0;
                            }
                            div {
                                 position: relative;
                                 &:after {
                                     right: 1px;
                                     bottom: -3px;
                                     content: "+";
                                     position: absolute;
                                 }
                            }
                            &[aria-expanded="true"] {
                                div {
                                    &:after {
                                     content: "-";
                                    }
                                }
                            }
                        }
                    }
                }
                &:before {
                    @media #{$medium-up} {
                        background: #4C4C4C;
                    }
                }
           }
        }
    }
    .top-fixed {
        background: #efefe9;
        padding: $spacing 0;
        max-height: auto;
        position: relative;
        @media #{$medium-up} {
            padding: $spacing * 1.5 0;
            max-height: 150px;
        }
    }
    h1 {
        font-size: rem-calc(24);
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: $spacing * 2;
    }
    .bloc {
        border: solid 2px #b31916;
        margin: $spacing * 3 0 $spacing * 1.5 0;
        position: relative;
        @media #{$medium-up} {
            margin: $spacing * 3 0 $spacing * 1.5 0;
        }
        &.basic {
            border: solid 2px #f38d17;
            .bloc-titol {
                border-top: solid 2px #f38d17;
                border-right: solid 2px #f38d17;
                border-left: solid 2px #f38d17;
            }
            table {
                thead {
                    td, th {
                        background-color: #f38d17;
                    }
                }
            }
            table.border-table {
                margin-bottom: 20px;
                tr {
                    td {
                        border: 0;
                        border-left: 2px #f38d17 solid;
                        border-bottom: 2px #f38d17 solid;
                        &:last-child {
                            border-right: 2px #f38d17 solid;
                        }

                    }
                    &:first-child td,
                    tbody &:first-child th {
                    	border-top: 2px #f38d17 solid;
                    }
                }

            }
        }
        &.elemental {
            border: solid 2px #d91016;
            .bloc-titol {
                border-top: solid 2px #d91016;
                border-right: solid 2px #d91016;
                border-left: solid 2px #d91016;
            }
            table {
                thead {
                    td, th {
                        background-color: #d91016;
                    }
                }
            }
            table.border-table {
                margin-bottom: 20px;
                tr {
                    td {
                        border: 0;
                        border-left: 2px #d91016 solid;
                        border-bottom: 2px #d91016 solid;
                        &:last-child {
                            border-right: 2px #d91016 solid;
                        }

                    }
                    &:first-child td,
                    tbody &:first-child th {
                    	border-top: 2px #d91016 solid;
                    }
                }

            }
        }
        &.intermedi {
            border: solid 2px #b31916;
            .bloc-titol {
                border-top: solid 2px #b31916;
                border-right: solid 2px #b31916;
                border-left: solid 2px #b31916;
            }
            table {
                thead {
                    td, th {
                        background-color: #b31916;
                    }
                }
            }
            table.border-table {
                margin-bottom: 20px;
                tr {
                    td {
                        border: 0;
                        border-left: 2px #b31916 solid;
                        border-bottom: 2px #b31916 solid;
                        &:last-child {
                            border-right: 2px #b31916 solid;
                        }

                    }
                    &:first-child td,
                    tbody &:first-child th {
                    	border-top: 2px #b31916 solid;
                    }
                }

            }
        }
        &.suficiencia {
            border: solid 2px #d93a7c;
            .bloc-titol {
                border-top: solid 2px #d93a7c;
                border-right: solid 2px #d93a7c;
                border-left: solid 2px #d93a7c;
            }
            table {
                thead {
                    td, th {
                        background-color: #d93a7c;
                    }
                }
            }
            table.border-table {
                margin-bottom: 20px;
                tr {
                    td {
                        border: 0;
                        border-left: 2px #d93a7c solid;
                        border-bottom: 2px #d93a7c solid;
                        &:last-child {
                            border-right: 2px #d93a7c solid;
                        }

                    }
                    &:first-child td,
                    tbody &:first-child th {
                    	border-top: 2px #d93a7c solid;
                    }
                }

            }
        }
        &.superior {
            border: solid 2px #af3060;
            .bloc-titol {
                border-top: solid 2px #af3060;
                border-right: solid 2px #af3060;
                border-left: solid 2px #af3060;
            }
            table {
                thead {
                    td, th {
                        background-color: #af3060;
                    }
                }
            }
            table.border-table {
                margin-bottom: 20px;
                tr {
                    td {
                        border: 0;
                        border-left: 2px #af3060 solid;
                        border-bottom: 2px #af3060 solid;
                        &:last-child {
                            border-right: 2px #af3060 solid;
                        }

                    }
                    &:first-child td,
                    tbody &:first-child th {
                    	border-top: 2px #af3060 solid;
                    }
                }

            }
        }
        .bloc-titol {
            position: absolute;
            right: -2px;
            top: -2.90rem;
            padding: $spacing * 0.5 $spacing * 0.75;
            background: white;
            h2 {
                font-size: rem-calc(18);
                font-weight: normal;
                color: #000;
            }
            @media #{$medium-up} {
                top: -2.95rem;
            }
        }
        .bloc-text {
            padding: $spacing;
            /*h3 {
                font-size: rem-calc(22);
                text-transform: none;
                color: #000;
                border-bottom: 0;
            }*/
            .dt {
                background: #b31916;
                padding: $spacing * 0.75 $spacing;
                color: white;
                font-weight: bold;
            }
            table {
                margin: 0;
                thead {
                    background-color: #f38d17;
                    td, th {
                        font-size: rem-calc(18);
                        color: #fff;
                        font-weight: bold;
                    }
                    th {
                        border-bottom: 10px solid;
                    }
                }
                p {
                    font-size: rem-calc(16);
                }
                tr {
                    td {
                        border: 0;
                        border-right: 10px white solid;
                        border-bottom: 10px white solid;
                        @media #{$medium-up} {
                            border-right: 20px white solid;
                            border-right: 50px white solid;
                        }
                        &:last-child {
                            border-right: 0;
                        }
                    }
                }
            }
            table.border-table {
                thead {
                    th {
                        border-bottom: 0;
                    }
                }
            }

        }
        .att {
            padding: $spacing;
            background: #e2e2d8;
            h4 {
                text-transform: uppercase;
                font-size: rem-calc(20);
            }
        }
        li {
            p {
                margin: 0;
                font-size: rem-calc(16);
            }
            ul, ol {
                margin-bottom: 0;
                font-weight: 300;
                li {
                    font-size: rem-calc(16);
                    font-weight: 300;
                }
            }
        }
        &.d1 {
            border: solid 2px #d93a7c;
            .bloc-titol {
                border-top: solid 2px #d93a7c;
                border-right: solid 2px #d93a7c;
                border-left: solid 2px #d93a7c;
                h2 {
                    color: #d93a7c;
                }
            }
            .dt {
                background-color: #d93a7c;
            }
        }
        .alert-1 {
            border: 0;
            text-align: left;
            margin-bottom: 0;
            h4 {
                &.alert__title {
                    color: #c30c28 !important;
                }
            }
        }

    }

    //FORMULARI CERCA
    form {
        &.houdiniForm {
            margin: 0;
            input {
                background: white;
                width: 60%;
                margin-right: $spacing;
                margin-bottom: $spacing;
                @media #{$medium-up} {
                    margin-bottom: $spacing;
                }
            }
            button[type=submit] {
                background: #c30c28;
                border-radius: 6px;
                position: relative;
                top: -4px;
                margin-right: $spacing;
                img {
                    margin:0;
                    padding: 12px;
                }
            }
            div, .btn {
                margin: 0;
            }
            #advanced-search-wrapper {
                position: absolute;
                width: 100%;
                background-color: #efefe9;
                z-index: 1;
                padding-left: 20px;
                left: -48px;
                .btn-cerca-a {
                    max-width: 200px;
                    margin: 20px 0;
                }
                .close-advanced {
                    position: absolute;
                    right: 0px;
                    top: -20px;
                }
                @media #{$medium-up} {
                    &:after {
                        content: '';
                        background-color: #efefe9;
                        display: block;
                        height: 150px;
                        width: 1000%;
                        position: absolute;
                        top: 0;
                        right: -1000%;
                    }
                }
                @media #{$large-up} {
                    &:after {
                        content: '';
                        background-color: #efefe9;
                        display: block;
                        height: 163px;
                        width: 1000%;
                        position: absolute;
                        top: 0;
                        right: -1000%;
                    }
                }
            }
        }
    }
    .listing {
        li {
            border-bottom: 1px solid #dfdfd6;
            padding-bottom: 15px;
            a {
                text-transform: none;
                color: #000;
            }
            span {
                color:#4c4c4c;
                font-size: 15px;
            }
        }
    }

}



/* Media Queries */

@media only screen and (min-width: 1190px) {
    .nivells-grm div p a span.nom {
        font-size: rem-calc(32);
    }
    .nivells-grm div p a span.num {
        font-size: rem-calc(50);
        bottom: -8px;
    }
}

.gramatica {
    .cercador-home {
        background-color: #efefe9;
        padding: 50px 30px;
    }
    //FORMULARI CERCA HOME
    form {
        &.houdiniForm {
            margin: 0;
            input {
                background: white;
                width: 60%;
                margin-right: $spacing;
                margin-bottom: $spacing;
                @media #{$medium-up} {
                    margin-bottom: $spacing;
                }
            }
            button[type=submit] {
                background: #c30c28;
                border-radius: 6px;
                position: relative;
                top: -4px;
                margin-right: $spacing;
                img {
                    margin:0;
                    padding: 12px;
                }
            }
            div, .btn {
                margin: 0;
            }
            #advanced-search-wrapper {
                position: relative;
                width: 100%;
                padding-left: 20px;
                left: -40px;
                .btn-cerca-a {
                    max-width: 200px;
                    margin: 20px 0;
                }
                .close-advanced {
                    position: absolute;
                    right: 0px;
                    top: -20px;
                }
            }
        }
    }
}
