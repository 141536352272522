.destacats {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  margin-bottom: $spacing * 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
  li {
    margin: 0;
    display: flex;
    padding: $spacing * 0.25;
    width: 100%;
    text-align: center;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:last-child {
      margin: 0;
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    @media screen and (min-width: 50em) {
      width: 33.33%;
    }
    @media #{$large-up} {
      width: 25%;
    }
  }
  a {
    &:hover,
    &:focus {
      img {
        opacity: 0.9;
      }
    }
  }
  &__item {
    text-decoration: none;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    line-height: 1.3;
    height: rem-calc(149);
    width: rem-calc(308);
    padding: $spacing;
    background-color: #444;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }

    &--color {
      &-1 {
        background-color: $dest-color-1;
      }
      &-2 {
        background-color: $dest-color-2;
      }
      &-3 {
        background-color: $dest-color-3;
      }
    }

    &--foto {
      background-size: cover;
      background-repeat: no-repeat;
      &-1 {
        background-image: url("http://picsum.photos/400/300/?random=1");
      }
      &-2 {
        background-image: url("http://picsum.photos/400/300/?random=2");
      }
    }

    &--overlay {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      &-1 {
        &:before {
          background-color: rgba(#000, 0.4);
        }
      }
      &-2 {
        &:before {
          background-color: rgba(#c30c28, 0.6);
        }
      }
      &-3 {
        &:before {
          background-color: rgba(#0c5463, 0.6);
        }
      }
      &-4 {
        &:before {
          background-color: rgba(#be4290, 0.6);
        }
      }
    }

    // no flexbox fallback
    .ie9 & {
      display: block;
    }
  }
  &__title {
    color: #fff !important;
    margin: 0 $spacing $spacing * 0.25;
    font-size: rem-calc(20);
    position: relative;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
  }
  &__subtitle {
    color: #fff;
    margin-bottom: 0;
    font-size: 1rem;
    font-style: italic;
    position: relative;
  }
  img {
    margin-bottom: 0;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }
}
