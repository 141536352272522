.houdiniForm {
    position: relative;
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }
    legend {
        font-weight: bold;
        text-transform: uppercase;
        font-size: rem-calc(20);
        line-height: 1.3;
        margin-bottom: $spacing;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
        @media #{$large-up} {
            font-size: rem-calc(24);
        }
    }
    div {
        margin-bottom: $spacing;
    }
    .column,
    .columns {
        margin-bottom: 0;
    }
    label {
        display: block;
        font-weight: bold;
        font-size: rem-calc(14);
        margin-bottom: $spacing * 0.25;
        text-transform: uppercase;
        .section--bg-2 & {
            color: #fff;
        }
        &.control {
            text-transform: none;
            font-weight: normal;
            cursor: default;
        }
        .info {
            text-transform: none;
            font-size: rem-calc(12);
            font-weight: normal;
        }
        &.error {
            color: #d60000;
            text-transform: none;
        }
        &.hidden {
            display: none;
        }
    }
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    textarea {
        color: #000;
        background-color: #f0f0f0;
        padding: 0.8rem 1rem;
        width: 100%;
        line-height: 1.6;
        border-radius: 6px;
        font-weight: normal;
        box-sizing: border-box;
        .section--bg-1 & {
            background-color: #fff;
        }
        &.error {
            border: 1px solid #d60000;
        }

    }
    .localitat {
        background-color: #fff !important;
    }
    textarea {
        height: 180px;
    }
    input[type="checkbox"] {
        appearance: checkbox;
        width: $spacing * 0.75;
        height: $spacing * 0.75;
        margin-right: $spacing * 0.125;
        vertical-align: baseline;
    }
    input[type="radio"] {
        appearance: radio;
        width: $spacing * 0.75;
        height: $spacing * 0.75;
        margin-right: $spacing * 0.125;
        vertical-align: baseline;
    }
    button {
        margin-bottom: 0;
    }

    // wtf-forms overwrites
    .select {
        margin-bottom: 0;
        width: 100%;
        font-weight: normal;
        select {
            border-radius: 6px;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            background-color: #f0f0f0;
            background-image: linear-gradient(to bottom, #f5f5f5, #e2e2e2);
            .section--bg-1 &,
            .cercador-jocs & {
                background-image: linear-gradient(to bottom, #ffffff, #ececec);
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem $focus-color;
            }
            &:active {
                color: inherit;
                background-color: #efefef;
            }
        }
    }
    .control input:focus ~ .control-indicator {
        box-shadow: 0 0 0 0.2rem $focus-color;
    }
    .control input:checked ~ .control-indicator {
        @include themify($themes) {
            background-color: themed("formElementColor");
        }
    }
    .checkbox .control-indicator {
        border-radius: 0;
    }

    .select2 li {
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        &::before {
            display: none !important;
        }
    }

    &.applied-filters {
        margin-bottom: 2.5rem !important;

        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        textarea {
            color: #000;
            background-color: #fff;
        }
        .select {
            select {
                background-color: #fff;
                background-image: linear-gradient(to bottom, #fff, #f5f5f5);
                &:focus {
                    box-shadow: 0 0 0 0.2rem $focus-color;
                }
                &:active {
                    color: inherit;
                    background-color: #efefef;
                }
            }
        }
        .buttons {
            button,
            .btn {
                margin-bottom: 0;
            }
        }
        .form-body {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
    &.simple-form {
    }

    &.form-submitted {
        opacity: 0.5;
    }
}

// Cercador publicacions
.pub-form {
    margin-bottom: 0;
}

// Selector idioma
.idioma-form {
    @media #{$height} {
        margin: $spacing * 1.5 0;
    }
    fieldset > div {
        @media screen and (min-width: 40em) {
            display: flex;
        }
    }
    label {
        text-align: center;
    }
    .select {
        margin-bottom: $spacing * 0.5;
        @media screen and (min-width: 40em) {
            margin-bottom: 0;
        }
    }
    .btn {
        margin-left: $spacing * 0.25;
    }
}

// Cercador de cursos
.cursos-form {
    background-color: #666;
    background-color: rgba(0, 0, 0, 0.7);
    padding: $spacing;
    @media #{$medium-up} {
        position: relative;
        padding-left: 0;
        margin-bottom: 0;
    }
    &:before {
        @media #{$medium-up} {
            content: "";
            position: relative;
            display: block;
            background-color: #666;
            background-color: rgba(0, 0, 0, 0.7);
            position: absolute;
            height: 100%;
            width: 500%;
            top: 0;
            right: 0;
            bottom: 0;
            left: -500%;
        }
    }
    fieldset {
        margin-bottom: $spacing * 1.5;
    }
    legend {
        color: #fff;
        font-weight: bold;
        font-size: 1rem;
        border-bottom: 1px solid #979797;
        margin-bottom: $spacing * 0.5;
        padding-bottom: $spacing * 0.5;
        width: 100%;
    }
    ul {
        @include undolist;
        margin-bottom: 0;
    }
    li {
        margin-bottom: 0;
        padding: $spacing * 0.5 0;
        line-height: 1.4;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
    label {
        color: #fff;
        font-size: rem-calc(14);
        font-weight: normal;
        margin-bottom: 0;
        &.control {
            text-transform: uppercase;
            padding-left: 2rem;
        }
    }
    svg {
        display: block;
        margin-bottom: 6px;
        position: relative;
        top: 4px;
    }
    .control-indicator {
        top: 0.125rem;
    }
    &__municipi {
        input {
            padding-right: rem-calc(40) !important;
            width: 100%;
            background-image: url("img/ico-search.png");
            background-repeat: no-repeat;
            background-position: 95% 50%;
            background-position: calc(100% - 0.75rem) 50%;
        }
    }
    &__dates {
        label {
            margin-bottom: $spacing * 0.5;
        }
    }
}
.nivells-list li {
    border-bottom: 1px solid rgba(#979797, 0.5);
    &:last-child {
        border: 0;
    }
    label {
        margin-bottom: 0;
    }
}

// Cercador de jocs
.cercador-jocs {
    margin-bottom: 0;
    .row,
    .section {
        margin-bottom: 0 !important;
    }
}
.search-jocs {
    div {
        display: flex;
    }
    label {
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: $spacing * 0.5;
    }
    input {
        color: #000;
        background-color: #f0f0f0;
        padding: 0.8rem 1rem;
        margin-right: $spacing * 0.5;
        // width: 100%;
        line-height: 1.6;
        border-radius: 6px;
        font-weight: normal;
        flex: 1 1 auto;
        @media #{$medium-up} {
            flex: 0 1 50%;
        }
    }
    button {
        background-color: $cpnl-color;
        border-radius: 6px;
        // padding: 0.8rem 0;
        // line-height: 1.6;
        width: rem-calc(52);
        img {
            margin-bottom: 0;
        }
        &.btn {
            width: auto;
            margin-left: 10px;
        }
    }
}
.filtres-jocs {
    background-color: #efefe9;
    padding: $spacing;
    margin-bottom: $spacing;
    @media #{$medium-up} {
        position: relative;
        padding-left: 0;
        margin-bottom: 0 !important;
    }
    &:before {
        @media #{$medium-up} {
            content: "";
            position: relative;
            display: block;
            background-color: #efefe9;
            position: absolute;
            height: 100%;
            width: 500%;
            top: 0;
            right: 0;
            bottom: 0;
            left: -500%;
        }
    }
    fieldset {
        margin-bottom: $spacing * 1.5;
        &:last-child {
            margin-bottom: 0;
        }
    }
    ul {
        margin-bottom: 0;
    }
    legend {
        font-weight: bold;
        font-size: 1rem;
        // border-bottom: 1px solid #979797;
        margin-bottom: $spacing * 0.5;
        // padding-bottom: $spacing * 0.5;
        width: 100%;
    }
    .control-indicator {
        background-color: #fff;
    }
}

// form sol·licitud
.solicitud-form {
    fieldset {
        margin-bottom: $spacing * 2;
    }
}
ul.inputs-list {
    @include undolist;
    li {
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 0;
        padding: $spacing * 0.5 0;
        @media #{$large-up} {
            width: 45%;
            float: left;
            margin-right: 5%;
            &:nth-child(2n) {
                margin-right: 0;
                margin-left: 5%;
            }
        }
    }
    .control {
        padding-left: 0;
        padding-right: 1.5rem;
        font-weight: bold;
        font-size: rem-calc(14);
        margin-bottom: 0;
        text-transform: uppercase;
        color: $body-font-color;
    }
    .control-indicator {
        left: auto;
        right: 0;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: fixed;
    top: 50%;
    left: 50%;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #ff4e69 transparent #ff4e69 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// range slider
// range slider (https://codepen.io/smhigley/pen/ObWbdy)
// ocultem controls quan hi ha js
#km1,
#km1 + p {
    .js & {
        display: none;
    }
}
// ocultem widget quan no hi ha js
.custom-slider-widget {
    margin-bottom: $spacing * 0.5 !important;
    .no-js & {
        display: none;
    }
}
.slider-wrapper {
    max-width: 100%;
    position: relative;
    width: 100%;
    margin-bottom: 0 !important;

    .custom-track {
        background-color: #fff;
        height: 5px;
        position: relative;
        width: 100%;
        display: block;
        margin-top: $spacing * 2;
        margin-bottom: $spacing * 0.5;
    }

    .custom-fill {
        background-color: darken(#d8d8d8, 0.8);
        border-radius: 5px;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        top: 0;
        width: 50%;
    }

    .custom-thumb {
        background-color: #d8d8d8;
        border-radius: 50%;
        // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        height: 20px;
        left: 50%;
        margin-left: -10px;
        margin-top: 0;
        position: absolute;
        top: -8px;
        width: 20px;
        // &:before {
        //   background-color: $light-grey;
        //   border-radius: 50%;
        //   box-shadow: 0 0 3px 0 darken($purple, 30%);
        //   content: "";
        //   height: 12px;
        //   left: 6px;
        //   position: absolute;
        //   top: 6px;
        //   width: 12px;
        // }
    }

    output {
        position: absolute;
        display: block;
        color: #fff;
        font-size: rem-calc(16);
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
    }

    input:focus ~ .custom-track {
        .custom-thumb {
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
        }
    }

    input[type="range"] {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
        &:focus + .custom-track .custom-thumb {
            background-color: #ff4e69;
        }
    }

    // make default slider transparent
    // still needs to have user interactions
    input[type="range"]::-ms-track {
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        margin: 0px;
        padding: 0px;
        border: 0 none;
        background: transparent;
        color: transparent;
        overflow: visible;
    }

    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 100%;
        -moz-appearance: none;
        margin: 0px;
        padding: 0px;
        border: 0 none;
        background: transparent;
        color: transparent;
        overflow: visible;
    }

    input[type="range"] {
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        margin: 0px;
        padding: 0px;
        border: 0 none;
        background: transparent;
        color: transparent;
        overflow: visible;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: transparent;
        border: transparent;
    }

    input[type="range"]:focus {
        outline: none;
    }

    // thumb needs to be same size as custom thumb
    input[type="range"]::-ms-thumb {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 0 none;
        background: transparent;
        // cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 0 none;
        background: transparent;
        // cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 0 none;
        background: transparent;
        // cursor: pointer;
        -webkit-appearance: none;
    }

    // microsoft IE10+ and Edge - eliminates default fills before and after thumb
    input[type="range"]::-ms-fill-lower {
        background: transparent;
        border: 0 none;
    }

    input[type="range"]::-ms-fill-upper {
        background: transparent;
        border: 0 none;
    }

    input[type="range"]::-ms-tooltip {
        display: none;
    }
}
.values {
    div {
        color: #fff;
        font-size: rem-calc(14);
        width: 50%;
        margin-top: 0;
    }
}
.value-1 {
    float: left;
}
.value-2 {
    float: right;
    text-align: right;
}
