.head {
  padding: $spacing * 1.5 0;
  margin-bottom: $spacing;

  @include themify($themes) {
    background-color: themed("headBgColor");
  }

  @media screen and (min-width: 40em) {
    margin-bottom: 0;
  }

  @media #{$medium-up} {
    margin-bottom: $spacing * 2;
  }
  @media #{$large-up} {
    margin-bottom: $spacing * 3;
  }

  &--collapsed {
    margin-bottom: 0;
    // @media #{$medium-up} {
    //   & + .submenu-container-large {
    //     margin-top: rem-calc(-92) !important;
    //   }
    // }
  }

  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.apartat,
.page-title {
  // text-transform: uppercase;
  margin-bottom: 0;
  font-size: rem-calc(36);
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: -0.38px;
  /*.jocs & {
    color: #fff;
    margin-bottom: 0;
  }*/
  a {
    text-decoration: none;
    color: #000000;
    &:hover {
        text-decoration: underline;
    }
  }
}
.apartat a {
  @include hover;
  color: #000;
}
.page-title {
  font-weight: 900;
  margin-bottom: $spacing * 2;
}
