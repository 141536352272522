.news {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: $spacing * 2;

  @media #{$large-up} {
    margin-bottom: $spacing * 4;
  }

  &__item {
    margin-bottom: $spacing;
    padding-bottom: $spacing;
    border-bottom: 1px solid #efefef;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
  }
  h2 {
    margin-bottom: $spacing * 0.5;
    font-weight: 900;
    font-size: rem-calc(24);
    line-height: 1.1;
    @media #{$medium-up} {
      font-size: rem-calc(26);
    }
    @media #{$large-up} {
      font-size: rem-calc(32);
    }
    a {
      @include themify($themes) {
        color: themed("titleColor");
      }
      @include hover;
    }
  }
  time {
    font-weight: bold;
    display: block;
    font-size: rem-calc(14);
/*     margin-bottom: $spacing * 0.5; */
    order: -2;
  }
  .categ {
    font-weight: bold;
    font-size: rem-calc(14);
    margin-bottom: $spacing * 0.5;
    order: -2;
  }
  p {
    margin-bottom: $spacing;
    font-size: rem-calc(14);
    @media screen and (min-width: 45em) {
      margin-bottom: 0;
    }
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
    a {
      @include hover;
      font-weight: bold;
    }
  }
  img {
    margin-bottom: 0;
  }
}
.news__item--simple p {
  margin-bottom: 0;
}
