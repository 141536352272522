.selectors-wrapper {
  display: flex;
  justify-content: space-between;
  .btn {
    background-color: #fff;
    color: $body-font-color !important;
    display: inline-block;
    border-radius: 6px;
    font-size: rem-calc(14);
    text-transform: uppercase;
    font-weight: bold;
    border-width: 0;
    @media #{$medium-up} {
      font-size: rem-calc(16);
      padding-top: rem-calc(13);
      padding-bottom: rem-calc(13);
      margin-bottom: 0;
      margin-right: $spacing;
    }
    &:focus-within {
      outline: 3px solid $focus-color;
    }
  }
}
.troba-curs {
  .selector {
    text-align: center;
    color: #fff;
    background-color: #a30921;
    border-radius: 4px;
    margin: 0;
    padding: $spacing * 0.5;
    flex-basis: 47%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    @media #{$medium-up} {
      background-color: transparent;
      padding: 0;
      flex-basis: 44%;
    }
    /*
    @media #{$large-up} {
      flex-basis: 44%;
    }
    */
    a:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    img {
      margin: 0 auto;
      margin-bottom: $spacing;
    }
    .btn {
      display: block;
      margin: auto auto 0;
    }
  }
  .section {
    margin-bottom: 0;
    &:nth-child(2) {
      .step {
        &:before {
          border-top-color: #c30c28;
        }
      }
    }
  }
}

.troba-curs-interior {
  h1 {
    font-size: rem-calc(18);
    margin-bottom: $spacing;
  }
  h2 {
    font-size: rem-calc(26);
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: $spacing * 1.5;
    text-align: center;
  }
  hr {
    margin: $spacing * 2 0;
  }
}
.troba-curs-form {
  fieldset {
    border: 0;
  }
  legend {
    @include visually-hidden;
  }
  label {
    display: block;
    font-weight: 900;
    margin-bottom: $spacing * 0.5;
  }
  input,
  select {
    width: 100%;
    border-radius: 6px;
    background-image: linear-gradient(to bottom, transparent, #dadada);
    font-size: 1rem;
    color: #000;
    font-weight: normal;
    padding: $spacing * 0.75 $spacing * 2 $spacing * 0.75 $spacing;
    box-sizing: border-box;
    &::placeholder {
      color: #000;
      // opacity: 1;
    }
  }
  select {
    padding-top: rem-calc(16);
    padding-bottom: rem-calc(16);
    &:focus {
      box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem $focus-color;
    }
  }
  &--search {
    div {
      position: relative;
    }
    button {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      height: rem-calc(56);
      padding: 0 0.75rem;
      display: flex;
      background-color: transparent;
      img {
        margin: auto;
        opacity: 0.4;
      }
    }
  }
  .select {
    width: 100%;
  }
  .btn {
    border-color: #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 6px;
    padding: $spacing * 0.5 $spacing * 3;
    font-size: rem-calc(18);
    font-weight: bold;
    @media #{$large-up} {
      padding-left: $spacing * 5;
      padding-right: $spacing * 5;
    }
    &:hover,
    &:focus {
      color: #fff !important;
      background-color: rgba(#fff, 0.25);
    }
    img {
      margin: 0 $spacing 0 0;
      vertical-align: sub;
    }
  }
  ul.resultats {
    @include undolist;
    font-size: rem-calc(16);
    margin-top: $spacing;
    @media #{$medium-up} {
      margin-left: $spacing;
      margin-right: $spacing;
    }
    @media #{$large-up} {
      margin-top: 0;
      margin-left: $spacing * 2;
      margin-right: $spacing * 2;
    }
    li {
      border-bottom: 1px solid #d8d8d8;
      padding: $spacing * 0.5 0;
      margin-bottom: 0;
      &:last-child {
        border-bottom: 0;
      }
    }
    label {
      font-weight: normal;
      color: #fff;
      margin-bottom: 0;
    }
    // wtf-forms overrides
    .control {
      padding-left: $spacing * 0.5;
    }
    .control-indicator {
      right: $spacing * 0.5;
      left: auto;
      border-radius: 50%;
      width: $spacing;
      height: $spacing;
    }
    .checkbox input:checked ~ .control-indicator {
      background-image: none;
      background-color: #eee;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $spacing * 0.5;
        height: $spacing * 0.5;
        background-color: #ff4e69;
        border-radius: 50%;
      }
    }
  }
}
.troba-curs-box {
  background-color: #fff;
  padding: $spacing;
  margin-bottom: $spacing;
  @media #{$medium-up} {
    padding: $spacing * 2;
  }
  > * {
    margin: 0;
    color: $body-font-color;
    + * {
      margin-top: $spacing * 0.5;
    }
  }
  a {
    color: $cpnl-color;
  }
}
.punts-atencio {
  @include undolist;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 30em) {
    margin: -$spacing;
    margin-bottom: $spacing * 2;
  }
  li {
    // flexbox fallback
    display: inline-block;
    vertical-align: top;

    background-color: #fff;
    padding: $spacing;
    margin: 0;
    color: $body-font-color;
    width: 100%;
    margin: 0 0 $spacing;
    display: flex;
    flex-direction: column;
    @media (min-width: 30em) {
      margin: $spacing;
      width: 40%;
      width: calc(50% - 3rem);
    }
    @media #{$medium-up} {
      padding: $spacing * 1.5;
      width: 28%;
      width: calc(33.333% - 3rem);
    }
    @media #{$large-up} {
      padding: $spacing * 2;
    }
    &:last-child {
      margin-bottom: $spacing;
    }
    a {
      @include hover;
    }
    h3 {
      margin-bottom: $spacing * 0.5;
      font-size: rem-calc(18);
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
      @media #{$large-up} {
        font-size: rem-calc(22);
      }
      a {
        color: $body-font-color;
      }
    }
    p {
      margin-bottom: $spacing * 0.5;
      font-size: rem-calc(16);
      a {
        color: $cpnl-color-secondary;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.close-btn,
.back-btn {
  position: absolute;
}
.close-btn {
  width: rem-calc(40);
  height: rem-calc(40);
  background-color: #fff;
  padding: 0;
  margin: 0;
  display: flex;
  top: -$spacing * 1.5;
  right: 0;
  @media #{$medium-up} {
    width: rem-calc(55);
    height: rem-calc(55);
    top: -$spacing * 2.5;
    right: $column-gutter * 0.5;
  }
  @media #{$large-up} {
    width: rem-calc(70);
    height: rem-calc(70);
  }
  @media #{$xlarge-up} {
    width: rem-calc(90);
    height: rem-calc(90);
  }
  svg {
    margin: auto;
  }
}
.back-btn {
  border: 1px solid #ebebeb;
  text-decoration: none;
  padding: $spacing * 0.125 $spacing * 0.25;
  top: 0;
  right: $column-gutter * 0.5;
  font-size: rem-calc(14);
  @media #{$medium-up} {
    padding: $spacing * 0.25 $spacing;
    font-size: rem-calc(16);
  }
  &:hover {
    background-color: rgba(#fff, 0.25);
  }
}
