.agenda-list {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  .home & {
    @media #{$medium-only} {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing;
    }
    @media #{$large-up} {
      display: flex;
      flex-wrap: wrap;
      gap: $spacing;
    }
  }
  li {
    border-bottom: 1px solid #979797;
    padding-bottom: $spacing;
    margin-bottom: $spacing;
    .home & {
      @media #{$medium-only} {
        flex-basis: calc(50% - #{$spacing}/ 2);
        margin-bottom: 0;
      }
      @media #{$large-up} {
        flex-basis: calc(33% - #{$spacing}/ 2);
        margin-bottom: 0;
      }
    }
    body:not(.home) & {
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    // .row:first-child {
    //   display: flex;
    //   flex-direction: column-reverse;
    //   @media #{$medium-up} {
    //     display: block;
    //   }
    //   > div:first-child {
    //     display: flex;
    //     flex-direction: column;
    //   }
    // }
  }
  &__title {
    font-weight: bold;
    font-size: rem-calc(22);
    margin-top: 0;
    margin-bottom: $spacing * 0.5;
    .home & {
      font-size: rem-calc(20);
      margin-bottom: 0;
    }
    // @media #{$medium-up} {
    //   font-size: rem-calc(26);
    //   .home & {
    //     font-size: rem-calc(22);
    //   }
    // }
    // @media #{$large-up} {
    //   font-size: rem-calc(32);
    //   .home & {
    //     font-size: rem-calc(24);
    //   }
    // }
    a {
      @include themify($themes) {
        color: themed("titleColor");
      }
      @include hover;
      .home & {
        color: #000;
      }
    }
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .categ {
    // text-transform: uppercase;
    font-size: rem-calc(14);
    margin-bottom: $spacing * 0.5;
    a {
      font-weight: 400;
      color: #000;
      @include hover;
    }
  }
  &__descr {
    font-size: 1rem;
    margin-bottom: $spacing * 0.5;
    + .row {
      margin-left: -$column-gutter * 0.5 !important;
      margin-right: -$column-gutter * 0.5 !important;
    }
  }
  &__lloc,
  &__horari {
    padding-left: $spacing;
    position: relative;
    font-size: rem-calc(14);
    margin-bottom: $spacing * 0.5;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.3em;
    }
    em {
      font-size: rem-calc(12);
    }
  }
  &__lloc {
    &:before {
      @include sprite("ico-lloc");
    }
  }
  &__horari {
    font-weight: bold;
    &:before {
      @include sprite("ico-hora");
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.date {
  // display: inline-block;
  // @media #{$medium-up} {
  //   display: block;
  // }
  // text-transform: uppercase;
  font-size: rem-calc(16);
  font-weight: normal;
  margin-bottom: $spacing;
  line-height: 1;
  display: block;
  // text-align: center;
  @include themify($themes) {
    color: themed("titleColor");
  }
  span {
    display: block;
    font-weight: 300;
    font-size: rem-calc(50);
    line-height: 1.1;
  }
  em {
    font-style: normal;
    font-size: rem-calc(14);
    font-weight: 300;
  }

  .multi-dates & {
    // font-size: rem-calc(14);
    // margin-bottom: $spacing/2;
    // display: inline-block;
    // float: left;
    // &:first-child {
    //   margin-right: $spacing;
    // }

    // @media #{$medium-up} {
    //   display: block;
    //   float: none;
    //   &:first-child {
    //     margin-right: 0;
    //   }
    // }

    span {
      font-size: rem-calc(50);
      // .home & {
      //   font-size: rem-calc(38);
      // }
    }
  }
}
.multi-dates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing * 0.5;
  align-items: center;
  //  @media #{$medium-up} {
  //  	display: block;
  //  }
  .fitxa--agenda & .date {
    top: 0;
  }
  .date {
    margin-bottom: 0;
  }
  .home & {
    margin-bottom: $spacing;
    gap: $spacing;
  }
  .arrow {
    position: relative;
    top: 5px;
    .home & {
      position: static;
    }
    path {
      @include themify($themes) {
        fill: themed("titleColor");
      }
      //   // float: left;
      //   position: relative;
      //   // width: 22px;
      //   display: block;
      //   // padding: 0.75rem;
      //   &:before {
      //     content: "";
      //     position: absolute;
      //     top: 26px;
      //     left: 0;
      //     @include themify($themes) {
      //       border-left: 10px solid themed("titleColor");
      //     }
      //     border-top: 10px solid transparent;
      //     border-bottom: 10px solid transparent;
      //   }

      //   &:after {
      //     content: "";
      //     position: absolute;
      //     left: 0;
      //     top: 27px;
      //     border-left: 9px solid #fff;
      //     border-top: 9px solid transparent;
      //     border-bottom: 9px solid transparent;
      //   }
    }
  }
  // @media #{$medium-up} {
  //   .arrow {
  //     float: none;
  //     position: relative;
  //     width: 22px;
  //     display: block;
  //     margin: 1rem auto 0.5rem;
  //     padding-bottom: 0;
  //     padding-right: 0;

  //     &:before {
  //       content: "";
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       border: none;
  //       @include themify($themes) {
  //         border-top: 10px solid themed("titleColor");
  //       }
  //       border-left: 10px solid transparent !important;
  //       border-right: 10px solid transparent;
  //     }

  //     &:after {
  //       content: "";
  //       position: absolute;
  //       left: 1px;
  //       top: 0;
  //       border: none;
  //       border-top: 9px solid #fff;
  //       border-left: 9px solid transparent;
  //       border-right: 9px solid transparent;
  //     }
  //   }
  // }
}
