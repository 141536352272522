// Validació
.carnet {
	margin-bottom: 5rem;
    p {
        span {
            display: block;
            font-weight: normal;
        }
    }
    .btn {
        margin-bottom: 0;
    }
    > * {
        margin: 0;
        + * {
            margin: 1rem 0 0;
        }
        p {
            margin-bottom: 0;
        }
    }
    &__text-smaller {
        font-size: .75rem !important;
    }
    &__text-small {
        font-size: .875rem !important;
    }
    &__text-normal {
        font-size: 1rem !important;
    }
    &__text-large {
        font-size: 1.375rem !important;
    }
    &__cercle {
        border-radius: 50%;
        width: 12.5rem;
        height: 12.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 900;
        margin-left: auto;
        margin-right: auto;
        svg {
            margin-bottom: .75rem;
        }
        &--ok {
            background-color: #4CBE71;
        }
        &--ko {
            background-color: #EB4F67;
        }
    }
    &__usuari {
        strong {
            font-weight: 900;
        }
    }
    &__border::before {
        content: '';
        display: block;
        width: 2rem;
        height: 1px;
        background-color: #000;
        margin: 0 auto 1rem auto;
    }
    // &__box {
    //     border: 2px solid #383838;
    //     background-color: #F3F3F3;
    //     padding: .5rem 3rem;
    //     border-radius: 8px;
    //     max-width: fit-content;
    //     margin-left: auto;
    //     margin-right: auto;
    // }
    
    form label {
    	text-transform: none;
		font-weight: normal;
    }
    
    form input {
    	margin: 1rem auto;
    	display: block;
    	padding: .5rem 1rem !important;
        border-radius: 8px !important;
        border: 2px solid #383838;
        background-color: #F3F3F3 !important;
        text-align:center;
        @media screen and (min-width: 400px) {
            width: 20rem !important;
        }
    }
}

.loyalty-card {
    width: 350px;
    max-width: 100%;
    min-height: 200px;
    border-radius: 10px;
    overflow: hidden;
    background: #c30c28 url(img/carnet-logo-fons.png) no-repeat;
    margin: 1rem auto 2rem auto;
    color: white;
    text-align: center;
    font-size: 16px;
    background-size: contain;
    background-position: 50% 50%;
    + div .btn {
        white-space: initial;
    }
}

.loyalty-card .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px 0px;
}

.loyalty-card  img {
    margin: 0;
}

// .loyalty-card .header .logo img {
//     width: 51px;
// }

.loyalty-card .header .issuer-name {
    font-size: 14px;
    margin-top: 8px;
    text-transform: uppercase;
    font-weight: bold;
}

// .loyalty-card .header:after {
//     height: 1px;
//     display: block;
//     width: 150px;
//     background: #fff;
//     border-right: 1px #fff;
//     content: '';
//     margin: 18px auto 0px;
// }

.loyalty-card .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
}

// .loyalty-card .body .top-row,
// .loyalty-card .body .data-row {
//     margin-bottom: 10px;
// }

.loyalty-card .body .data-row.twoItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.loyalty-card .body .program-name {
    text-align: center;
    font-size: 22px;
    font-weight: 900;
}

.loyalty-card .body .loyalty-label {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 12px;
}

.loyalty-card .body .loyalty-value {
    font-size: 12px;
}

.loyalty-card .body .barcode {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0 0;
}


.loyalty-card .body .startItem {
    margin-bottom: 1rem;
    & .loyalty-value {
        font-size: 14px;
    }
}

.barcode .barcode-wrapper {
    background-color: #fff;
    // border-radius: 4px;
    // padding: 12px;
    margin-bottom: 18px;

    border-radius: 50%;
    width: 12.5rem;
    height: 12.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.barcode .barcode-wrapper img.barcode-type-qrcode {
    display: block;
}
.barcode-value {
    margin-bottom: .25rem;
}
.barcode-value .loyalty-label {
    font-size: 9px !important;
    font-weight: bold;
}
.barcode-value .loyalty-value {
    font-size: 16px !important;
    font-weight: 900;
}

.benefici {
    &__side {
        border-top: 1px solid #C80C28;
        border-bottom: 1px solid #C80C28;
        padding: 1.125rem 1.5rem 1.125rem 0;
        
        dl {
            margin-bottom: 0;
            dt {
                text-transform: uppercase;
                margin-bottom: .25rem;
            }
            dd {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            
        }
    }
    
    &__institucio {
        padding: 25px;
        margin: 1.5rem 0;
        background-color: #f4f4f4;
        
        ul {
            margin:1rem 0 0;
            list-style-type: none;
            @include undolist;
            
            li {
                display:inline;
                margin: 0 0 0 1.5rem;
                padding-right: 1.5rem;
                position: relative;
                &:after {
                    content: ' ';
                    display: block;
                    width: 1px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: #000;
                    
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
            
        }
    }
}