.boxes-nav {
    // Declaració de variables:
    // Espai entre columnes:
    --gap: #{$spacing * 0.5};
    // Número de columnes:
    --columns: 5;
    ul {
        @include undolist;
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap);
        font-size: rem-calc(13);
        font-weight: bold;
    }
    li {
        flex-basis: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
        margin-bottom: 0;
    }
    a {
        display: block;
        border-radius: 6px;
        padding: $spacing * 0.5;
        border: 1px solid;
        color: #000;
        height: 100%;
        @include hover;
        @include themify($themes) {
            border-color: themed("primaryColor");
        }
        &:hover,
        &:focus {
            color: #fff;
            @include themify($themes) {
                background-color: themed("primaryColor");
            }
        }
    }
    li.active a {
        color: #fff;
        @include themify($themes) {
            background-color: themed("primaryColor");
        }
    }
}
