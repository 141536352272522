.cal {
  width: 100%;
  font-size: rem-calc(16);
  background-color: #fff;
  border-collapse: separate;
  border-spacing: 2px;
  @media #{$medium-up} {
    // font-size: rem-calc(16);
    // border-spacing: 3px;
    margin-bottom: $spacing * 2;
  }
  @media #{$large-up} {
    // font-size: rem-calc(18);
    // border-spacing: 5px;
    margin-bottom: $spacing * 3;
  }

  thead {
    th {
      background-color: transparent !important;
      text-align: center;
      padding: 0;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .mes {
        color: #000;
        font-weight: bold;
        font-size: rem-calc(18);
        a {
          color: #000;
          @include hover;
        }
      }
    }
    // span {
    //   display: inline-block;
    // }
  }

  tbody {
    td {
      background-color: #fff;
      text-align: center;
      border: 0;
      color: #000;
      padding: 0;
      width: calc(100% / 7);
      vertical-align: middle;
      line-height: 3;
      font-weight: normal;
      @media #{$large-up} {
        line-height: 4;
        .home & {
          line-height: 3;
        }
      }
      &.empty {
        // background-color: #eee;
        // background-color: rgba(#ededed, 0.5);
        opacity: 0.4;
      }
      &.today {
        span {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: rem-calc(47);
          height: rem-calc(47);
          border-radius: 50%;
          border: 2px solid #000;
          font-size: inherit;
        }
      }
      .agenda--small & {
        line-height: 3;
      }
    }
    a {
      text-decoration: none;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      width: rem-calc(39);
      height: rem-calc(39);
      border-radius: 50%;
      @include themify($themes) {
        background-color: themed("primaryColor");
      }
      &:hover {
        background-color: #fff;
        border: 2px solid;
        @include themify($themes) {
          border-color: themed("primaryColor");
        }
        @include themify($themes) {
          color: themed("primaryColor");
        }
      }
    }
  }
  .arrow {
    padding: $spacing * 0.5;
    text-decoration: none;
    // &.prev {
    //   margin-right: $spacing;
    //   .icon {
    //     @include sprite("bt-anterior-calendario");
    //   }
    // }
    // &.next {
    //   margin-left: $spacing;
    //   .icon {
    //     @include sprite("bt-siguiente-calendario");
    //   }
    // }
  }
  // &__tooltip {
  //   // display: block;
  //   line-height: 1.2;
  //   background-color: #fff;
  //   border: 1px solid #a5a5a5;
  //   padding: $spacing;
  //   font-weight: bold;
  //   font-size: rem-calc(14);
  //   @include themify($themes) {
  //     color: themed("primaryColor");
  //   }
  // }

  &--small {
    font-size: rem-calc(16);
    border-spacing: 1px;
    tbody {
      td {
        line-height: 2;
        font-weight: normal;
      }
      .theme-cpnl & {
        a {
          background-color: $cpnl-color-secondary;
          &:hover {
            color: $cpnl-color-secondary;
            background-color: #fff;
          }
        }
      }
    }
  }
}
