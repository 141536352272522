.noticies-list {
  list-style: none;
  padding-left: 0;
  @media #{$medium-up} {
    column-count: 2;
    column-gap: $column-gutter;
  }
}
.noticia {
  break-inside: avoid;
  padding-bottom: $spacing;
  margin-bottom: $spacing;
  border-bottom: 1px solid #979797;
  display: flex;
  flex-direction: column;
  h2 {
    font-size: rem-calc(26);
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(30);
    }
    @media #{$large-up} {
      font-size: rem-calc(32);
    }
    a {
      @include hover;
      @include themify($themes) {
        color: themed("titleColor");
      }
    }
  }
  &__meta {
    order: -2;
    margin-bottom: $spacing * 0.75;
  }
  &__cnl {
  	font-size: rem-calc(14);
  	color: #000;
  }
  &__categ {
    display: inline-block;
    font-size: rem-calc(12);
    font-weight: 900;
    @include hover;
    @include themify($themes) {
      color: themed("boxesColor");
    }
  }
  
  time {
    display: block;
    font-weight: bold;
    font-size: rem-calc(14);
  }
  img {
    order: -1;
    margin-bottom: $spacing * 0.75;
    margin-right: auto;
  }
  p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 1rem;
  }

  // noticia destacada
  &-container {
    background-size: cover;
/*    background-image: url("http://picsum.photos/1500/700/"); */
    position: relative;
    margin-bottom: $spacing;
    @media #{$medium-up} {
      height: 30rem;
    }
    a {
      color: #fff !important;
    }
    p,
    time {
      color: #fff;
    }
  }
  &-wrapper {
    background-color: #000;
    background-color: rgba(#000, 0.5);
    padding: $spacing 0;
    @media #{$medium-up} {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    @media #{$medium-up} {
      padding: $spacing * 1.5 0;
    }
    @media #{$large-up} {
      padding: $spacing * 2 0;
    }
  }
  &--dest {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
    .noticia__cnl {
    	margin-left: 10px;
      @include themify($themes) {
        color: #fff;
      }
    }
    .noticia__categ {
      @include themify($themes) {
        background-color: themed("boxesColor");
      }
      padding: $spacing * 0.125 $spacing * 0.25;
      margin-bottom: $spacing * 0.25;
      position: relative;
      &:before {
        content: "";
        width: $spacing * 0.5;
        @include themify($themes) {
          background-color: themed("boxesColor");
        }
        display: block;
        position: absolute;
        left: -$spacing * 0.5;
        top: 0;
        bottom: 0;
      }
    }
  }
}
.noticies-list2 {
  list-style: none;
  padding-left: 0;
  @include block-grid(1);
  @media screen and (min-width: 30em) {
    @include block-grid(2);
  }
  @media screen and (min-width: 50em) {
    @include block-grid(3);
  }
  @media #{$medium-up} {
    @include block-grid(4);
  }
  .noticia {
    padding-bottom: 0;
    border: 0;
  }
  h2 {
    font-size: rem-calc(22);
  }
  .noticia__cnl {
  	display: block;
  }
}
